import { CopyText } from '@app/components/CopyText';
import { GenericModal } from '@app/components/GenericModal';
import { QrGenerator } from '@app/components/QrGenerator';
import { EventData } from '@app/services/eventService';
import { formatDate, formatTime } from '@app/util/helpers';
import { Box, Button, CardMedia, Grid, Stack, Typography } from '@mui/material';

interface Props {
  open: boolean;
  onClose: () => void;
  data: EventData;
}
export function DetailModal({ open, onClose, data }: Props) {
  const keyValueRenderer = (key: string, value: string | JSX.Element) => (
    <>
      <Grid item xs={12} sm={3}>
        <Typography variant="body1" fontWeight="bold">
          {key} :
        </Typography>
      </Grid>
      <Grid item xs={12} sm={9} mb={2}>
        <Typography>{value}</Typography>
      </Grid>
    </>
  );

  return (
    <GenericModal title={data.name} isOpen={open} onClose={onClose} width={450}>
      <CardMedia
        component="img"
        src={data.image}
        alt={data.name}
        sx={{
          height: 180,
          width: 1,
          objectFit: 'contain',
          bgcolor: 'gray',
          mb: 2,
        }}
      />
      <Grid container>
        {keyValueRenderer('Title', data.name)}
        {keyValueRenderer('Description', data.description)}
        {keyValueRenderer('Date', formatDate(data.startDate))}
        {keyValueRenderer(
          'Time',
          `${formatTime(data.startDate)} - ${formatTime(data.endDate)}`,
        )}
        {keyValueRenderer('Venue', data.venue)}
        {keyValueRenderer('Link', data.link || '-')}
        <Grid item xs={12} mt={4} mb={2}>
          <CopyText lable="Code" value={data.pointSource.qrSecret} />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ mx: 'auto' }}>
            <QrGenerator id={data.id} value={data.pointSource.qrSecret} />
          </Box>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="end" width={1}>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Close
        </Button>
      </Stack>
    </GenericModal>
  );
}
