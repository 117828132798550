/* eslint-disable @typescript-eslint/no-floating-promises */
import { Btn } from '@app/components/Btn';
import { useAuth } from '@app/hooks/useAuth';
import { createStudent } from '@app/services/studentsService';
import { Typography, Grid, TextField, Stack, Button } from '@mui/material';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

export interface AddStudentFormData {
  firstName: string;
  lastName: string;
  studentId: string;
  email: string;
}

interface Props {
  onClose: () => void;
}

export function AddForm(props: Props) {
  const { onClose } = props;

  const [error, setError] = useState<string>();
  const [studentId, setStudentId] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [degreeAudit, setDegreeAudit] = useState<string>();

  const { tokenData } = useAuth();
  const token = tokenData?.token || '';
  const queryClient = useQueryClient();
  const mutation = useMutation(createStudent, {
    onSuccess: () => {
      queryClient.invalidateQueries('students');
      onClose();
    },
    onError: (err: Error) => {
      setError(err.message);
    },
  });

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (firstName && lastName && email && studentId) {
      mutation.mutate({
        token,
        firstName,
        lastName,
        email,
        phone,
        degreeAudit,
        customId: studentId,
      });
    }
  };

  return (
    <form onSubmit={submitForm}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="number"
            size="small"
            label="Student ID"
            value={studentId}
            onChange={e => setStudentId(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            size="small"
            label="First Name"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            size="small"
            label="Last Name"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="email"
            size="small"
            label="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="tel"
            size="small"
            label="Phone Number"
            value={phone}
            onChange={e => setPhone(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="text"
            size="small"
            label="Degree Audit"
            value={degreeAudit}
            onChange={e => setDegreeAudit(e.target.value)}
          />
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography color="error">{error}</Typography>
          </Grid>
        )}
      </Grid>

      <Stack direction="row" justifyContent="end" width={1} margin={1}>
        <Button
          variant="outlined"
          color="primary"
          sx={{ mr: 1 }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Btn
          variant="contained"
          color="primary"
          type="submit"
          isLoading={mutation.isLoading}
        >
          Add Student
        </Btn>
      </Stack>
    </form>
  );
}
