/* eslint-disable unicorn/no-nested-ternary */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { Btn } from '@app/components/Btn';
import { FileUploadField } from '@app/components/FileUploadField';
import {
  ResourceFolderItemData,
  createResourceFolderItem,
  updateResourceFolderItem,
} from '@app/services/otherContentService';
import { Newspaper, YouTube } from '@mui/icons-material';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

interface Props {
  token: string;
  onClose: () => void;
  folderId: string;
  queryKey: string;
  id?: string;
  defaultValues?: ResourceFolderItemData;
}
export function FolderItemForm(props: Props) {
  const [mode, setMode] = useState<string | undefined>(
    props.defaultValues
      ? props.defaultValues?.youtubeUrl
        ? 'video'
        : 'article'
      : undefined,
  );

  if (!mode) {
    return (
      <Stack direction="column" gap={2} m={2}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<YouTube sx={{ color: '#f61c0d' }} />}
          onClick={() => setMode('video')}
        >
          Video (YouTube)
        </Button>
        <Typography align="center">OR</Typography>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<Newspaper sx={{ color: '#7243ff' }} />}
          onClick={() => setMode('article')}
        >
          Article
        </Button>
      </Stack>
    );
  }

  if (mode === 'video') {
    return <VideoItemForm {...props} />;
  }

  return <ArticleItemForm {...props} />;
}

function VideoItemForm({
  token,
  onClose,
  folderId,
  id,
  defaultValues,
  queryKey,
}: Props) {
  const [values, setValues] = useState({
    youtubeUrl: defaultValues?.youtubeUrl || '',
    title: defaultValues?.title || '',
  });
  const handleChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues(prev => ({ ...prev, [field]: event.target.value }));
    };
  const [image, setImage] = useState<File>();
  const fileOnChange = (files: File[]) => {
    if (files.length > 0) {
      setImage(files[0]);
    }
  };

  const queryClient = useQueryClient();
  const addMutation = useMutation(createResourceFolderItem, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      onClose();
    },
  });

  const editMutation = useMutation(updateResourceFolderItem, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      onClose();
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      ...values,
      image,
      description: '',
      btnText: '',
      url: '',
      token,
    };
    if (id) {
      editMutation.mutate({ ...data, id });
    } else {
      addMutation.mutate({ ...data, folderId });
    }
  };

  const error = id ? editMutation.error : addMutation.error;
  const isLoading = editMutation.isLoading || addMutation.isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4} pt={2}>
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Video Name"
          value={values.title}
          onChange={handleChange('title')}
        />
        <TextField
          fullWidth
          required
          type="url"
          size="small"
          label="Youtube Video URL"
          value={values.youtubeUrl}
          onChange={handleChange('youtubeUrl')}
        />
        <FileUploadField
          label="Image"
          onChange={fileOnChange}
          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        />

        {error && (
          <Typography color="error">{(error as Error).message}</Typography>
        )}
        <Stack direction="row" justifyContent="end" width={1} spacing={2}>
          <Btn
            variant="contained"
            color="info"
            type="submit"
            isLoading={isLoading}
          >
            Save
          </Btn>
        </Stack>
      </Stack>
    </form>
  );
}

function ArticleItemForm({
  token,
  onClose,
  folderId,
  id,
  defaultValues,
  queryKey,
}: Props) {
  const [values, setValues] = useState({
    title: defaultValues?.title || '',
    description: defaultValues?.description || '',
    url: defaultValues?.url || '',
  });
  const handleChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues(prev => ({ ...prev, [field]: event.target.value }));
    };
  const [image, setImage] = useState<File>();
  const fileOnChange = (files: File[]) => {
    if (files.length > 0) {
      setImage(files[0]);
    }
  };

  const queryClient = useQueryClient();
  const addMutation = useMutation(createResourceFolderItem, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      onClose();
    },
  });

  const editMutation = useMutation(updateResourceFolderItem, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      onClose();
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      ...values,
      image,
      youtubeUrl: '',
      btnText: '',
      token,
    };
    if (id) {
      editMutation.mutate({ ...data, id });
    } else {
      addMutation.mutate({ ...data, folderId });
    }
  };

  const error = id ? editMutation.error : addMutation.error;
  const isLoading = editMutation.isLoading || addMutation.isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4} pt={2}>
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Article Title"
          value={values.title}
          onChange={handleChange('title')}
        />
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Description"
          value={values.description}
          onChange={handleChange('description')}
        />
        <TextField
          fullWidth
          required
          type="url"
          size="small"
          label="Article URL"
          value={values.url}
          onChange={handleChange('url')}
        />
        <FileUploadField
          label="Article Thumbnail"
          onChange={fileOnChange}
          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        />

        {error && (
          <Typography color="error">{(error as Error).message}</Typography>
        )}
        <Stack direction="row" justifyContent="end" width={1} spacing={2}>
          <Btn
            variant="contained"
            color="info"
            type="submit"
            isLoading={isLoading}
          >
            Save
          </Btn>
        </Stack>
      </Stack>
    </form>
  );
}
