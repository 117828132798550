/* eslint-disable unicorn/no-null */
import { DataWrapper } from '@app/components/DataWrapper';
import { GenericTable, TableHeader } from '@app/components/GenericTable';
import { getAllStudents, StudentData } from '@app/services/studentsService';
import { Typography } from '@mui/material';
import { TableRowActions } from './TableRowActions';

const headers: TableHeader<StudentData>[] = [
  {
    headerLabel: 'ID',
    isSortable: true,
    key: 'customId',
  },
  {
    headerLabel: 'First Name',
    isSortable: true,
    key: 'firstName',
  },
  {
    headerLabel: 'Last Name',
    isSortable: true,
    key: 'lastName',
  },
  {
    headerLabel: 'Email',
    isSortable: true,
    key: 'email',
  },

  {
    headerLabel: 'Phone',
    isSortable: true,
    key: 'phone',
    renderCell: row => row.phone || '-',
  },
  {
    headerLabel: 'Degree Audit',
    isSortable: false,
    key: 'degreeAudit',
    renderCell: row =>
      row.degreeAudit ? (
        <Typography
          variant="body2"
          component="span"
          sx={{
            color: '#fff',
            bgcolor: 'success.main',
            borderRadius: 4,
            px: 1,
          }}
        >
          Yes
        </Typography>
      ) : (
        <Typography
          variant="body2"
          component="span"
          sx={{
            color: '#fff',
            bgcolor: 'lightgray',
            borderRadius: 4,
            px: 1,
          }}
        >
          No
        </Typography>
      ),
  },
  {
    headerLabel: 'Registered',
    isSortable: false,
    key: 'hasLogged',
    renderCell: row =>
      row.hasLogged ? (
        <Typography
          variant="body2"
          component="span"
          sx={{
            color: '#fff',
            bgcolor: 'success.main',
            borderRadius: 4,
            px: 1,
          }}
        >
          Yes
        </Typography>
      ) : (
        <Typography
          variant="body2"
          component="span"
          sx={{
            color: '#fff',
            bgcolor: 'lightgray',
            borderRadius: 4,
            px: 1,
          }}
        >
          No
        </Typography>
      ),
  },
  {
    headerLabel: 'Actions',
    isSortable: false,
    key: 'id',
    renderCell: row => <TableRowActions data={row} />,
  },
];

export function Table() {
  return (
    <DataWrapper<StudentData>
      id="students"
      filterConfig={[
        {
          field: 'customId',
          label: 'Student ID',
          type: 'contains',
          isCaseInsensitive: true,
          value: '',
        },
        {
          field: 'firstName',
          label: 'First Name',
          type: 'contains',
          value: '',
          isCaseInsensitive: true,
        },
        {
          field: 'lastName',
          label: 'Last Name',
          type: 'contains',
          value: '',
          isCaseInsensitive: true,
        },
        {
          field: 'email',
          label: 'Email',
          type: 'contains',
          value: '',
          isCaseInsensitive: true,
        },
      ]}
      // eslint-disable-next-line @typescript-eslint/require-await
      fetchData={({ token, options }) => getAllStudents(token, options)}
      render={(data, { sortBy, setSortBy }) => (
        <GenericTable<StudentData>
          tableHeaders={headers}
          data={data}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
      )}
    />
  );
}
