import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { Stack } from '@mui/material';
import { Actions } from './Actions';
import { Table } from './Table';

export function Tutorings() {
  return (
    <Layout>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BreadCrumbs
          links={[
            {
              label: 'Dashboard',
              link: '/',
            },
            {
              label: 'Tutorings',
              link: '/events/tutorings',
            },
          ]}
        />
        <Actions />
      </Stack>
      <Table />
    </Layout>
  );
}
