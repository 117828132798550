import { Box, Stack } from '@mui/material';
import { IStudentProfileDetails } from '@app/services/studentOpportunitiesService';
import { ContentSection } from './ContentSection';
import { StudentData } from './StudentData';

interface Props {
  data: IStudentProfileDetails;
}
export function Profile({ data }: Props) {
  const contents = {
    education: data?.section?.filter(v => v.type === 'education') || [],
    experience: data?.section?.filter(v => v.type === 'experience') || [],
    activities: data?.section?.filter(v => v.type === 'activities') || [],
  };

  return (
    <Box bgcolor={`${data?.dividerColor || '#f8f8f8'}`}>
      <Stack gap={2}>
        <StudentData studentProfileDetails={data} />
        {data && (
          <>
            {contents.education.length > 0 && (
              <ContentSection
                title="Education"
                content={contents.education}
                background={data.sectionColor ?? ''}
              />
            )}
            {contents.experience.length > 0 && (
              <ContentSection
                title="Experience"
                content={contents.experience}
                background={data.sectionColor ?? ''}
              />
            )}

            {contents.activities.length > 0 && (
              <ContentSection
                title="Activities"
                content={contents.activities}
                background={data.sectionColor ?? ''}
              />
            )}
          </>
        )}
      </Stack>
    </Box>
  );
}
