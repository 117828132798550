import { FoldersPage } from '@app/components/FolderView';

export function FinacialLiteracyFolders() {
  return (
    <FoldersPage
      title="Financial Literacy"
      linkPrefix="/resources/financial-literacy"
      folderType="FINANCIAL_LITERACY"
      queryKey="financial-literacy"
    />
  );
}
