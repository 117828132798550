import { ConfirmDeleteModal } from '@app/components/ConfirmDeleteModal';
import { GenericModal, useModal } from '@app/components/GenericModal';
import { useAuth } from '@app/hooks/useAuth';
import {
  deleteJobOpportunities,
  JobOpportunityData,
} from '@app/services/studentOpportunitiesService';
import { Button, Stack } from '@mui/material';
import { Form } from './Form';

interface Props {
  data: JobOpportunityData;
}

export function TableRowActions({ data }: Props) {
  const {
    isOpen: isEditModalOpen,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModal();

  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  const { tokenData } = useAuth();
  const token = tokenData?.token || '';

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" spacing={1} pt={2}>
        <Button
          disableElevation
          variant="contained"
          size="small"
          color="warning"
          onClick={openEditModal}
        >
          Edit
        </Button>

        <Button
          disableElevation
          variant="contained"
          size="small"
          color="error"
          onClick={openDeleteModal}
        >
          Delete
        </Button>
      </Stack>

      <GenericModal
        title="Edit Job Opportunity"
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        width={800}
        useOnlyCloseButton
      >
        <Form
          onClose={closeEditModal}
          token={token}
          id={data.id}
          defaultValues={data}
        />
      </GenericModal>
      <ConfirmDeleteModal
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        text={data.title}
        deleteFn={() => deleteJobOpportunities({ token, id: data.id })}
        queryKey="jobOpportunities"
      />
    </>
  );
}
