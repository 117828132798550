import { GenericModal, useModal } from '@app/components/GenericModal';
import { Add, TableView } from '@mui/icons-material';
import { Button, Stack, Box } from '@mui/material';
import { AddByCsvForm } from './AddByCsvForm';
import { AddDegreeAuditByCsvForm } from './AddDegreeAuditByCsvForm';
import { AddForm } from './AddForm';

export function Actions() {
  //  For CSV Modal
  const {
    isOpen: isCsvModalOpen,
    openModal: csvModalOpen,
    closeModal: csvModalClose,
  } = useModal();

  //  For Student Form Modal
  const {
    isOpen: isFormModalOpen,
    openModal: formModalOpen,
    closeModal: formModalClose,
  } = useModal();

  //  For Degree Audit CSV Modal
  const {
    isOpen: isDegreeAuditModalOpen,
    openModal: degreeAuditModalOpen,
    closeModal: degreeAuditModalClose,
  } = useModal();

  return (
    <Box>
      <Stack direction="row" justifyContent="end" gap={2} mb={4}>
        <Button
          variant="outlined"
          startIcon={<TableView />}
          onClick={degreeAuditModalOpen}
          sx={{ mr: 2 }}
        >
          Degree Audit From CSV
        </Button>
        <Button
          variant="outlined"
          startIcon={<TableView />}
          onClick={csvModalOpen}
        >
          Import From CSV
        </Button>
        <Button variant="contained" startIcon={<Add />} onClick={formModalOpen}>
          Student
        </Button>
      </Stack>

      <GenericModal
        title="Update Degree Audit"
        isOpen={isDegreeAuditModalOpen}
        onClose={degreeAuditModalClose}
        useOnlyCloseButton
      >
        <AddDegreeAuditByCsvForm onClose={degreeAuditModalClose} />
      </GenericModal>
      <GenericModal
        title="Import Students"
        isOpen={isCsvModalOpen}
        onClose={csvModalClose}
        useOnlyCloseButton
      >
        <AddByCsvForm onClose={csvModalClose} />
      </GenericModal>
      <GenericModal
        title="Add Student"
        isOpen={isFormModalOpen}
        onClose={formModalClose}
        useOnlyCloseButton
      >
        <AddForm onClose={formModalClose} />
      </GenericModal>
    </Box>
  );
}
