import { DataQueryOptions, Results } from '@app/components/DataWrapper';
import { apiCall, apiCallVoid, getFormData } from '@app/util/helpers';
import {
  EVENT_ALL_URL,
  EVENT_CREATE_URL,
  EVENT_DELETE_URL,
  EVENT_NOTIFY_URL,
  EVENT_UPDATE_URL,
  LECTURE_ALL_URL,
  LECTURE_CREATE_URL,
  LECTURE_DELETE_URL,
  LECTURE_UPDATE_URL,
  TUTORING_ALL_URL,
  TUTORING_CREATE_URL,
  TUTORING_DELETE_URL,
  TUTORING_UPDATE_URL,
} from 'config';

export type PointType =
  | 'LECTURE_ATTENDANCE'
  | 'TUTORING_ATTENDANCE'
  | 'EVENT_ATTENDANCE'
  | 'FINANCIAL_AID'
  | 'STUDENT_PORTAL_LOGIN';

export interface EventData {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  description: string;
  venue: string;
  link?: string;
  image: string;
  isNetworkingEvent: boolean;
  pointSource: {
    id: string;
    type: PointType;
    score: number;
    qrSecret: string;
  };
}
export type LectureData = Omit<
  EventData,
  'image' | 'venue' | 'link' | 'venue' | 'isNetworkingEvent'
>;
export type TutoringData = LectureData;

interface Kwargs {
  token: string;
  options: DataQueryOptions;
}

export async function getAllLectures({
  token,
  options,
}: Kwargs): Promise<Results<LectureData>> {
  return apiCall({
    url: LECTURE_ALL_URL,
    token,
    json: options,
  });
}

export async function getAllEvents({
  token,
  options,
}: Kwargs): Promise<Results<EventData>> {
  return apiCall({
    url: EVENT_ALL_URL,
    token,
    json: options,
  });
}

export async function getAllTutorings({
  token,
  options,
}: Kwargs): Promise<Results<TutoringData>> {
  return apiCall({
    url: TUTORING_ALL_URL,
    token,
    json: options,
  });
}

interface CreateEventOptions {
  token: string;
  name: string;
  description: string;
  image: File | undefined;
  startDate: string;
  endDate: string;
  venue: string;
  link: string | undefined;
}
export async function createEvent({
  token,
  ...data
}: CreateEventOptions): Promise<void> {
  return apiCallVoid({
    url: EVENT_CREATE_URL,
    token,
    formData: getFormData(data),
    successMessage: 'Event created successfully',
  });
}

type CreateTutoringOptions = Omit<
  CreateEventOptions,
  'image' | 'venue' | 'link'
>;
export async function createTutoring({
  token,
  ...data
}: CreateTutoringOptions): Promise<void> {
  return apiCallVoid({
    url: TUTORING_CREATE_URL,
    token,
    json: data,
    successMessage: 'Tutoring created successfully',
  });
}

type CreateLectureOptions = CreateTutoringOptions;
export async function createLecture({
  token,
  ...data
}: CreateLectureOptions): Promise<void> {
  return apiCallVoid({
    url: LECTURE_CREATE_URL,
    token,
    json: data,
    successMessage: 'Lecture created successfully',
  });
}

interface EditEventOptions extends CreateEventOptions {
  id: string;
}
export async function editEvent({
  token,
  id,
  ...data
}: EditEventOptions): Promise<void> {
  return apiCallVoid({
    url: `${EVENT_UPDATE_URL}${id}`,
    token,
    formData: getFormData(data),
    successMessage: 'Event updated successfully',
  });
}

type EditTutoringOptions = Omit<EditEventOptions, 'image' | 'venue' | 'link'>;
export async function editTutoring({
  token,
  id,
  ...data
}: EditTutoringOptions): Promise<void> {
  return apiCallVoid({
    url: `${TUTORING_UPDATE_URL}${id}`,
    token,
    json: data,
    successMessage: 'Tutoring updated successfully',
  });
}

type EditLectureOptions = EditTutoringOptions;
export async function editLecture({
  token,
  id,
  ...data
}: EditLectureOptions): Promise<void> {
  return apiCallVoid({
    url: `${LECTURE_UPDATE_URL}${id}`,
    token,
    json: data,
    successMessage: 'Lecture updated successfully',
  });
}

interface DeleteOptions {
  token: string;
  id: string;
  url: string;
}
async function deleteItem({ token, id, url }: DeleteOptions): Promise<void> {
  return apiCallVoid({
    url: `${url}${id}`,
    token,
    method: 'DELETE',
    successMessage: 'Item deleted successfully',
  });
}

export async function deleteEvent({
  token,
  id,
}: Omit<DeleteOptions, 'url'>): Promise<void> {
  await deleteItem({ token, id, url: EVENT_DELETE_URL });
}

export async function deleteTutoring({
  token,
  id,
}: Omit<DeleteOptions, 'url'>): Promise<void> {
  await deleteItem({ token, id, url: TUTORING_DELETE_URL });
}

export async function deleteLecture({
  token,
  id,
}: Omit<DeleteOptions, 'url'>): Promise<void> {
  await deleteItem({ token, id, url: LECTURE_DELETE_URL });
}

interface NotifyEventOptions {
  token: string;
  id: string;
}
export async function notifyEvent({
  token,
  id,
}: NotifyEventOptions): Promise<void> {
  return apiCallVoid({
    url: `${EVENT_NOTIFY_URL}${id}`,
    token,
  });
}
