import { useIsMobile } from '@app/hooks/useIsMobile';
import { IStudentProfileDetails } from '@app/services/studentOpportunitiesService';
import { Email, Phone, School, Web } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';

interface Props {
  studentProfileDetails?: IStudentProfileDetails;
}

export function StudentData({ studentProfileDetails }: Props) {
  const isMobile = useIsMobile();

  return (
    <Box
      position="relative"
      bgcolor={`${studentProfileDetails?.sectionColor ?? '#f8f8f8'}`}
    >
      <Box
        height={200}
        bgcolor={`${studentProfileDetails?.headerColor ?? '#fca959'}`}
      />

      {studentProfileDetails?.profilePicture && (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img
          src={studentProfileDetails?.profilePicture}
          style={{
            position: 'absolute',
            top: 80,
            left: 32,
            borderRadius: '100%',
            width: 150,
            height: 150,
            border: '4px solid #FFFFFF',
          }}
        />
      )}

      <Box mx={4} mt={6} mb={4}>
        <Stack
          direction={`${isMobile ? 'column' : 'row'}`}
          justifyContent="space-between"
        >
          <Box maxWidth="60%">
            <Typography variant="h5" fontWeight="bold">
              {studentProfileDetails?.name}
            </Typography>
            <Typography fontWeight="bold" mb={2}>
              {studentProfileDetails?.subTitle}
            </Typography>
            {isMobile && (
              <Stack gap={1} my={2}>
                {studentProfileDetails?.work && (
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Web />
                    <Typography>{studentProfileDetails?.work}</Typography>
                  </Stack>
                )}
                {studentProfileDetails?.education && (
                  <Stack direction="row" alignItems="center" gap={2}>
                    <School />
                    <Typography>{studentProfileDetails?.education}</Typography>
                  </Stack>
                )}
                {studentProfileDetails?.email && (
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Email />
                    <Typography>{studentProfileDetails?.email}</Typography>
                  </Stack>
                )}
                {studentProfileDetails?.phone && (
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Phone />
                    <Typography>{studentProfileDetails?.phone}</Typography>
                  </Stack>
                )}
              </Stack>
            )}
            <Typography fontWeight="bold">Summary</Typography>
            <Typography>{studentProfileDetails?.summary}</Typography>
          </Box>

          {!isMobile && (
            <Stack gap={1}>
              {studentProfileDetails?.work && (
                <Stack direction="row" alignItems="center" gap={2}>
                  <Web /> <Typography>{studentProfileDetails?.work}</Typography>
                </Stack>
              )}
              {studentProfileDetails?.education && (
                <Stack direction="row" alignItems="center" gap={2}>
                  <School />
                  <Typography>{studentProfileDetails?.education}</Typography>
                </Stack>
              )}
              {studentProfileDetails?.email && (
                <Stack direction="row" alignItems="center" gap={2}>
                  <Email />
                  <Typography>{studentProfileDetails?.email}</Typography>
                </Stack>
              )}
              {studentProfileDetails?.phone && (
                <Stack direction="row" alignItems="center" gap={2}>
                  <Phone />
                  <Typography>{studentProfileDetails?.phone}</Typography>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
      </Box>
    </Box>
  );
}
