/* eslint-disable @typescript-eslint/no-floating-promises */
import { Btn } from '@app/components/Btn';
import { createLecture, editLecture } from '@app/services/eventService';
import {
  addTime,
  formaOutputTime,
  formatInputDate,
  formatInputTime,
} from '@app/util/helpers';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

interface Props {
  token: string;
  onClose: () => void;
  id?: string;
  defaultValues?: {
    name: string;
    description: string;
    startDate: string;
    endDate: string;
  };
}
export function Form({ token, onClose, id, defaultValues }: Props) {
  const [values, setValues] = useState({
    name: defaultValues?.name || '',
    description: defaultValues?.description || '',
    date: defaultValues?.startDate
      ? formatInputDate(defaultValues.startDate)
      : '',
    startDate: defaultValues?.startDate
      ? formatInputTime(defaultValues.startDate)
      : '',
    endDate: defaultValues?.endDate
      ? formatInputTime(defaultValues.endDate)
      : '',
  });

  const handleChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues(prev => ({ ...prev, [field]: event.target.value }));
    };

  const queryClient = useQueryClient();
  const addMutation = useMutation(createLecture, {
    onSuccess: () => {
      queryClient.invalidateQueries('lectures');
      onClose();
    },
  });

  const editMutation = useMutation(editLecture, {
    onSuccess: () => {
      queryClient.invalidateQueries('lectures');
      onClose();
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      name: values.name,
      description: values.description,
      startDate: formaOutputTime(values.date, values.startDate),
      endDate: formaOutputTime(values.date, values.endDate),
    };
    if (id) {
      editMutation.mutate({ token, id, ...data });
    } else {
      addMutation.mutate({ token, ...data });
    }
  };

  const error = id ? editMutation.error : addMutation.error;
  const isLoading = editMutation.isLoading || addMutation.isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4} pt={2}>
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Title"
          value={values.name}
          onChange={handleChange('name')}
        />
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Description"
          value={values.description}
          onChange={handleChange('description')}
          multiline
          rows={3}
        />
        <TextField
          fullWidth
          required
          type="date"
          size="small"
          label="Date"
          value={values.date}
          onChange={handleChange('date')}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          required
          type="time"
          size="small"
          label="Start Time"
          value={values.startDate}
          onChange={handleChange('startDate')}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          required
          type="time"
          size="small"
          label="End Time"
          value={values.endDate}
          onChange={handleChange('endDate')}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: { min: addTime(values.startDate, 1) },
          }}
        />
        {error && (
          <Typography color="error">{(error as Error).message}</Typography>
        )}
        <Stack direction="row" justifyContent="end" width={1} spacing={2}>
          <Button variant="outlined" color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Btn
            variant="contained"
            color="primary"
            type="submit"
            isLoading={isLoading}
          >
            {id ? 'Update' : 'Create'}
          </Btn>
        </Stack>
      </Stack>
    </form>
  );
}
