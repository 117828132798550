import { FolderItemsPage } from '@app/components/FolderView/FolderItemsPage';

export function FinacialLiteracyFolderItems() {
  return (
    <FolderItemsPage
      title="Financial Literacy"
      linkPrefix="/resources/financial-literacy"
      queryKey="financial-literacy"
    />
  );
}
