import { ContentCopy } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';

interface Props {
  lable: string;
  value: string;
}

export function CopyText({ lable, value }: Props) {
  return (
    <Stack alignItems="center">
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        pl={1}
        sx={{
          borderRadius: 5,
          borderColor: 'info.main',
          borderWidth: 2,
          borderStyle: 'solid',
        }}
      >
        <Typography align="center">
          {lable}:{' '}
          <Typography fontWeight="bold" component="span">
            {value}
          </Typography>
        </Typography>
        <Button
          variant="contained"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={() => navigator.clipboard.writeText(value)}
          startIcon={<ContentCopy />}
          color="info"
          size="small"
        >
          Copy
        </Button>
      </Stack>
    </Stack>
  );
}
