import {
  BusinessCenter,
  Event,
  ExpandLess,
  ExpandMore,
  Feed,
  History,
  HistoryEdu,
  LocalAtm,
  MilitaryTech,
  People,
  School,
  Settings,
  Topic,
  Work,
} from '@mui/icons-material';
import {
  Box,
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/styles';
import MuiList from '@mui/material/List';

export const navLinks = [
  {
    name: 'Users',
    Icon: People,
    link: '/users',
    index: 0,
    links: [
      {
        name: 'Students',
        Icon: People,
        link: '/users/students',
      },
      {
        name: 'Staff',
        Icon: People,
        link: '/users/staff',
      },
    ],
  },
  {
    name: 'SBT Points',
    Icon: MilitaryTech,
    link: '/points',
    index: 1,
    links: [
      {
        name: 'Points System',
        Icon: Settings,
        link: '/points/system',
      },
      {
        name: 'Ponts History',
        Icon: History,
        link: '/points/history',
      },
    ],
  },
  {
    name: 'Events',
    Icon: Work,
    link: '/events',
    index: 2,
    links: [
      {
        name: 'Lectures',
        Icon: School,
        link: '/events/lectures',
      },
      {
        name: 'Tutorings',
        Icon: HistoryEdu,
        link: '/events/tutorings',
      },
      {
        name: 'Events & Workshops',
        Icon: Event,
        link: '/events/other',
      },
    ],
  },
  {
    name: 'Resources',
    Icon: Feed,
    link: '/resources',
    index: 3,
    links: [
      {
        name: 'Financial Literacy',
        Icon: LocalAtm,
        link: '/resources/financial-literacy',
      },
      {
        name: 'Institutional Resources',
        Icon: Topic,
        link: '/resources/institutional-resources',
        disabled: true,
      },
    ],
  },
  {
    name: 'Student Opportunities',
    Icon: Feed,
    link: '/student-opportunities',
    index: 4,
    links: [
      {
        name: 'Scholarships',
        Icon: LocalAtm,
        link: '/student-opportunities/scholarships',
      },
      {
        name: 'Job Opportunities',
        Icon: LocalAtm,
        link: '/student-opportunities/job-opportunity',
      },
      {
        name: 'Internships',
        Icon: LocalAtm,
        link: '/student-opportunities/internship',
      },
      {
        name: 'Feedbacks & Reviews',
        Icon: LocalAtm,
        link: '/student-opportunities/feedbacks',
      },
    ],
  },
  {
    name: 'Business Library',
    Icon: BusinessCenter,
    link: '/business-library',
    showInHome: true,
  },
  {
    name: 'Settings',
    Icon: Settings,
    link: '/settings',
    showInHome: true,
  },
];

const List = styled(MuiList)({
  // selected and (selected + hover) states
  '&& .Mui-selected, && .Mui-selected:hover': {
    backgroundColor: '#575a69',
  },
  // hover states
  '& .MuiListItemButton-root:hover': {
    backgroundColor: '#474c6b',
  },
});

export function NavLinks() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const onClick = (link: string) => () => navigate(link);
  const storedData = localStorage.getItem('navLinksOpened');
  const [opened, setOpened] = useState<boolean[]>(
    storedData ? (JSON.parse(storedData) as boolean[]) : [false, false],
  );
  const toggle = (index: number) => () => {
    setOpened(prev => {
      const newOpened = [...prev];
      newOpened[index] = !newOpened[index];
      localStorage.setItem('navLinksOpened', JSON.stringify(newOpened));
      return newOpened;
    });
  };

  return (
    <List disablePadding>
      <Stack gap={1}>
        {navLinks.map(link => (
          <Box key={link.name}>
            <ListItemButton
              onClick={link.links ? toggle(link.index) : onClick(link.link)}
              selected={pathname.includes(link.link)}
              sx={{ px: 4, color: 'white', margin: 1, borderRadius: 20 }}
            >
              <ListItemIcon>
                <link.Icon sx={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary={link.name} sx={{ fontWeight: 'bold' }} />
              {link.links &&
                (opened[link.index] ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
            {link.links && (
              <Collapse in={opened[link.index]} timeout="auto" unmountOnExit>
                <List disablePadding sx={{ ml: 4 }}>
                  {link.links.map(subLink => (
                    <ListItemButton
                      key={subLink.name}
                      onClick={onClick(subLink.link)}
                      selected={pathname.includes(subLink.link)}
                      sx={{
                        pl: 8,
                        color: 'white',
                        margin: 1,
                        borderRadius: 20,
                      }}
                      disabled={subLink.disabled}
                    >
                      {/* <ListItemIcon>
                      <subLink.Icon sx={{ color: 'white' }} />
                    </ListItemIcon> */}
                      <ListItemText
                        primary={subLink.name}
                        sx={{ fontWeight: 'bold' }}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </Box>
        ))}
      </Stack>
    </List>
  );
}
