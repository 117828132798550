import { DataQueryOptions, Results } from '@app/components/DataWrapper';
import { apiCall, apiCallVoid, getFormData } from '@app/util/helpers';
import {
  RESOURCE_FOLDER_ITEM_URL,
  RESOURCE_FOLDER_URL,
  RESOURCE_URL,
} from 'config';

export type IResourceType =
  | 'FINANCIAL_LITERACY'
  | 'INSTITUTIONAL_RESOURCE'
  | 'STUDNET_OPPORTUNITY'
  | 'BUSINESS_LIBRARY';

export interface ResourceFolderItemData {
  id: string;
  url: string;
  image?: string;
  title: string;
  description: string;
  youtubeUrl?: string;
  btnText?: string;
  folderId?: string;
}

export interface ResourceFolderData {
  id: string;
  image?: string;
  title: string;
}

interface GetFolderOptoins {
  token: string;
  type: IResourceType;
  options: DataQueryOptions;
}

interface GetFolderItemsOptoins {
  token: string;
  folderId: string;
  options: DataQueryOptions;
}

interface CreateFolderOptions {
  token: string;
  type: IResourceType;
  image: File | undefined;
  title: string;
}
interface CreateFolderItemOptions {
  token: string;
  folderId: string;
  url: string | undefined;
  image: File | undefined;
  title: string;
  description: string | undefined;
  youtubeUrl: string | undefined;
  btnText: string | undefined;
}

interface UpdateFolderOptions {
  id: string;
  token: string;
  image: File | undefined;
  title: string;
}

interface UpdateFolderItemOptions {
  id: string;
  token: string;
  url: string | undefined;
  image: File | undefined;
  title: string;
  description: string | undefined;
  youtubeUrl: string | undefined;
  btnText: string | undefined;
}

interface DeleteOptions {
  token: string;
  id: string;
}

export async function getResourceFolders({
  token,
  type,
  options,
}: GetFolderOptoins): Promise<Results<ResourceFolderItemData>> {
  return apiCall({
    url: `${RESOURCE_FOLDER_URL}list`,
    method: 'POST',
    token,
    json: { ...options, type },
  });
}

export async function getResourceFolderItems({
  token,
  folderId,
  options,
}: GetFolderItemsOptoins): Promise<Results<ResourceFolderItemData>> {
  return apiCall({
    url: `${RESOURCE_FOLDER_ITEM_URL}list/${folderId}`,
    method: 'POST',
    token,
    json: options,
  });
}

export async function createResourceFolder({
  token,
  ...data
}: CreateFolderOptions): Promise<ResourceFolderData> {
  return apiCall({
    url: `${RESOURCE_FOLDER_URL}create`,
    method: 'POST',
    token,
    formData: getFormData(data),
    successMessage: 'Content created successfully',
  });
}

export async function createResourceFolderItem({
  token,
  folderId,
  ...data
}: CreateFolderItemOptions): Promise<ResourceFolderData> {
  return apiCall({
    url: `${RESOURCE_FOLDER_ITEM_URL}create/${folderId}`,
    method: 'POST',
    token,
    formData: getFormData(data),
    successMessage: 'Content created successfully',
  });
}

export async function updateResourceFolder({
  token,
  id,
  ...data
}: UpdateFolderOptions): Promise<ResourceFolderItemData> {
  return apiCall({
    url: `${RESOURCE_FOLDER_URL}update/${id}`,
    method: 'POST',
    token,
    formData: getFormData(data),
    successMessage: 'Content updated successfully',
  });
}

export async function updateResourceFolderItem({
  token,
  id,
  ...data
}: UpdateFolderItemOptions): Promise<ResourceFolderItemData> {
  return apiCall({
    url: `${RESOURCE_FOLDER_ITEM_URL}update/${id}`,
    method: 'POST',
    token,
    formData: getFormData(data),
    successMessage: 'Content updated successfully',
  });
}

export async function deleteResource({
  token,
  id,
}: DeleteOptions): Promise<void> {
  return apiCallVoid({
    url: `${RESOURCE_URL}${id}`,
    method: 'DELETE',
    token,
    successMessage: 'Content deleted successfully',
  });
}
