import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { getStudentPublicProfile } from '@app/services/studentOpportunitiesService';
import { Stack } from '@mui/material';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Profile } from './StudentProfile/Profile';

export function StudentProfile() {
  const { id = '' } = useParams<{ id: string }>();
  const { data } = useQuery(['studentProfile', id], () =>
    getStudentPublicProfile({ id }),
  );

  return (
    <Layout>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BreadCrumbs
          links={[
            {
              label: 'Dashboard',
              link: '/',
            },
            {
              label: 'Students',
              link: '/users/students',
            },
            {
              label: 'Profile',
              link: '/',
            },
          ]}
        />
      </Stack>
      {data && <Profile data={data} />}
    </Layout>
  );
}
