import { GenericModal, useModal } from '@app/components/GenericModal';
import { useAuth } from '@app/hooks/useAuth';
import { IResourceType } from '@app/services/otherContentService';
import { CreateNewFolder } from '@mui/icons-material';
import { Button, Stack, Box } from '@mui/material';
import { FolderForm } from './FolderForm';

interface Props {
  queryKey: string;
  folderType: IResourceType;
}
export function FolderListActions({ queryKey, folderType }: Props) {
  const { tokenData } = useAuth();
  const token = tokenData?.token || '';

  const {
    isOpen: isAddFormOpen,
    openModal: openAddForm,
    closeModal: closeAddForm,
  } = useModal();

  return (
    <Box>
      <Stack direction="row" justifyContent="end" gap={2} mb={4}>
        <Button
          variant="contained"
          startIcon={<CreateNewFolder />}
          onClick={openAddForm}
        >
          New
        </Button>
      </Stack>

      <GenericModal
        title={
          <Stack direction="row" alignItems="center" gap={1}>
            <CreateNewFolder /> Add New Folder
          </Stack>
        }
        isOpen={isAddFormOpen}
        onClose={closeAddForm}
        useOnlyCloseButton
      >
        <FolderForm
          token={token}
          onClose={closeAddForm}
          queryKey={queryKey}
          folderType={folderType}
        />
      </GenericModal>
    </Box>
  );
}
