/* eslint-disable @typescript-eslint/no-floating-promises */
import { DeleteSweep } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { theme } from '@theme/theme';
import { useMutation, useQueryClient } from 'react-query';
import { Btn } from './Btn';
import { GenericModal } from './GenericModal';

interface Props {
  text: string | React.ReactNode;
  open: boolean;
  onClose: () => void;
  deleteFn: () => Promise<void>;
  queryKey?: string;
}

export function ConfirmDeleteModal({
  text,
  open,
  onClose,
  deleteFn,
  queryKey,
}: Props) {
  const queryClient = useQueryClient();
  const mutation = useMutation(deleteFn, {
    onSuccess: () => {
      if (queryKey) queryClient.invalidateQueries(queryKey);
      onClose();
    },
  });

  const onDelete = () => {
    mutation.mutate();
  };

  return (
    <GenericModal
      title={
        <Stack direction="row" alignItems="center" gap={1}>
          <DeleteSweep /> Confirm Delete
        </Stack>
      }
      isOpen={open}
      onClose={onClose}
      headerColor={theme.palette.error.main}
      useOnlyCloseButton
    >
      <Typography textAlign="center" fontWeight="bold" mb={2}>
        &quot;{text}&quot;
      </Typography>
      <Typography textAlign="center" fontWeight="bold" mb={4}>
        Are you sure want to delete this? This cannot be undone.
      </Typography>

      <Stack
        direction="row"
        justifyContent="space-around"
        width={1}
        spacing={2}
      >
        <Btn
          variant="contained"
          color="error"
          onClick={onDelete}
          isLoading={mutation.isLoading}
        >
          Yes, delete
        </Btn>
        <Button variant="contained" color="warning" onClick={onClose}>
          No, cancel
        </Button>
      </Stack>
    </GenericModal>
  );
}
