import {
  CHANGE_PASSWORD_URL,
  FORGOT_PASSWORD_URL,
  LOGIN_URL,
  RESET_PASSWORD_BY_ADMIN_URL,
  RESET_PASSWORD_URL,
} from 'config';
import { toast } from 'react-toastify';

export function loginService(email: string, password: string) {
  return fetch(LOGIN_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
    }),
  });
}

interface ChangePasswordOptions {
  token: string;
  oldPassword: string;
  newPassword: string;
}
export async function changePassword({
  token,
  ...body
}: ChangePasswordOptions) {
  const res = await fetch(CHANGE_PASSWORD_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  if (res.status === 200) {
    toast.success('Password changed successfully');
    return;
  }
  if (res.status === 400) {
    const error = await res.json();
    throw new Error(error as string);
  }
  throw new Error('Something went wrong');
}

interface ForgotPasswordOptions {
  email: string;
}
export async function forgotPassword(data: ForgotPasswordOptions) {
  const res = await fetch(FORGOT_PASSWORD_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (res.status === 200) return;
  if (res.status === 400) {
    const error = await res.json();
    throw new Error(error as string);
  }
  throw new Error('Something went wrong');
}

interface ResetPasswordOptions {
  token: string;
  newPassword: string;
}
export async function resetPassword(data: ResetPasswordOptions) {
  const res = await fetch(RESET_PASSWORD_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (res.status === 200) return;
  if (res.status === 400) {
    const error = await res.json();
    throw new Error(error as string);
  }
  throw new Error('Something went wrong');
}

interface ResetPasswordByAdminOptions {
  token: string;
  userId: string;
  newPassword: string;
}
export async function resetPasswordByAdmin({
  token,
  userId,
  newPassword,
}: ResetPasswordByAdminOptions) {
  const res = await fetch(`${RESET_PASSWORD_BY_ADMIN_URL}${userId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ newPassword }),
  });
  if (res.status === 200) {
    toast.success('Password changed successfully');
    return;
  }
  if (res.status === 400) {
    const error = await res.json();
    throw new Error(error as string);
  }
  throw new Error('Something went wrong');
}
