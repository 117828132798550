import { apiCall } from '@app/util/helpers';
import { SYSTEM_CONFIG_UPDATE_URL, SYSTEM_CONFIG_URL } from 'config';

export interface SystemConfigEntry {
  id: string;
  type: string;
  value: string;
  createdAt: string;
  updatedAt: string;
}

export async function getSystemConfig(
  token: string,
): Promise<SystemConfigEntry[]> {
  const res: { pointConfigs: SystemConfigEntry[] } = await apiCall({
    method: 'GET',
    url: SYSTEM_CONFIG_URL,
    token,
  });
  return res.pointConfigs;
}

interface SystemConfigUpdateOptions {
  token: string;
  items: { [id: string]: string };
}
export async function updateSystemConfig({
  token,
  items,
}: SystemConfigUpdateOptions): Promise<void> {
  return apiCall({
    method: 'POST',
    url: SYSTEM_CONFIG_UPDATE_URL,
    token,
    json: items,
  });
}
