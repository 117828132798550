import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DropzoneArea } from 'react-mui-dropzone';

const useStyles = makeStyles(() => ({
  dropzone: {
    minHeight: 'unset',
    padding: 12,
    color: 'gray',
    borderStyle: 'solid',
    borderRadius: 16,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
  text: {
    fontSize: '1rem',
  },
}));

interface Props {
  onChange: (files: File[]) => void;
  required?: boolean;
  showPreviews?: boolean;
  fileLimit?: number;
  acceptedFiles?: string[];
  label?: string;
}

export function FileUploadField({
  onChange,
  required,
  showPreviews,
  fileLimit = 1,
  acceptedFiles,
  label,
}: Props) {
  const classes = useStyles();

  return (
    <Box sx={{ position: 'relative', '& img': { objectFit: 'cover' } }}>
      <Typography
        fontSize={16}
        sx={{
          zIndex: 1,
          px: 1,
          color: 'rgba(0, 0, 0, 0.6)',
          bgcolor: '#fff',
          transform: 'scale(0.75)',
          position: 'absolute',
          transformOrigin: 'left',
          top: -12,
          left: 8,
        }}
      >
        {label} {required && '*'}
      </Typography>
      <DropzoneArea
        classes={{
          root: classes.dropzone,
          text: classes.text,
        }}
        onChange={onChange}
        onDrop={onChange}
        acceptedFiles={acceptedFiles}
        filesLimit={fileLimit}
        showPreviews={showPreviews}
        previewGridProps={{ item: { xs: 6 } }}
        inputProps={{
          required,
          style: {
            display: 'block',
            width: 1,
            height: 1,
            position: 'absolute',
            zIndex: -1,
            left: '50%',
            top: '50%',
          },
        }}
        maxFileSize={5_000_000}
      />
    </Box>
  );
}
