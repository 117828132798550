export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

export const LOGIN_URL = `${API_URL}/api-auth/login/dashboard/`;
export const CHANGE_PASSWORD_URL = `${API_URL}/api-auth/change-password/`;
export const FORGOT_PASSWORD_URL = `${API_URL}/api-auth/forgot-password/`;
export const RESET_PASSWORD_URL = `${API_URL}/api-auth/reset-password/`;
export const RESET_PASSWORD_BY_ADMIN_URL = `${API_URL}/api-auth/reset-password-by-admin/`;

export const STUDENT_ALL_URL = `${API_URL}/student/all/`;
export const STUDENT_CSV_CREATE_URL = `${API_URL}/student/create-from-csv/`;
export const STUDENT_CREATE_URL = `${API_URL}/student/create/`;
export const STUDENT_EDIT_URL = `${API_URL}/user/update/`;
export const STUDENT_DELETE_URL = `${API_URL}/student/delete/`;
export const STUDENT_DEGREE_AUDIT_CSV_URL = `${API_URL}/student/update-degree-audit-from-csv/`;
export const STUDENT_RESEND_INVITE_URL = `${API_URL}/student/resend-invite/`;

export const LECTURERS_URL = `${API_URL}/lecturer/all/`;
export const LECTURER_CREATE_URL = `${API_URL}/lecturer/create/`;
export const LECTURER_UPDATE_BASIC_URL = `${API_URL}/user/update/`;
export const LECTURER_UPDATE_ADDITIONAL_URL = `${API_URL}/lecturer/update/`;
export const LECTURER_DELETE_URL = `${API_URL}/lecturer/delete/`;
export const LECTURER_IMAGE_ADD_URL = `${API_URL}/lecturer/image/add/`;
export const LECTURER_IMAGE_DELETE_URL = `${API_URL}/lecturer/image/delete/`;
export const LECTURER_RESEND_INVITE_URL = `${API_URL}/lecturer/resend-invite/`;

export const LECTURE_ALL_URL = `${API_URL}/event/lecture/all/`;
export const EVENT_ALL_URL = `${API_URL}/event/event/all/`;
export const TUTORING_ALL_URL = `${API_URL}/event/tutoring/all/`;

export const LECTURE_CREATE_URL = `${API_URL}/event/lecture/create/`;
export const EVENT_CREATE_URL = `${API_URL}/event/event/create/`;
export const TUTORING_CREATE_URL = `${API_URL}/event/tutoring/create/`;

export const LECTURE_UPDATE_URL = `${API_URL}/event/lecture/update/`;
export const EVENT_UPDATE_URL = `${API_URL}/event/event/update/`;
export const TUTORING_UPDATE_URL = `${API_URL}/event/tutoring/update/`;

export const LECTURE_DELETE_URL = `${API_URL}/event/lecture/delete/`;
export const EVENT_DELETE_URL = `${API_URL}/event/event/delete/`;
export const TUTORING_DELETE_URL = `${API_URL}/event/tutoring/delete/`;

export const EVENT_NOTIFY_URL = `${API_URL}/event/event/notify/`;

export const POINT_CONFIG_URL = `${API_URL}/point/config/`;
export const UPDATE_POINT_CONFIG_ENTRIES_URL = `${API_URL}/point/config/points/update/`;
export const UPDATE_ACTIVE_SEMESTER_URL = `${API_URL}/point/config/semester/set-active/`;
export const CREATE_SEMESTER_URL = `${API_URL}/point/config/semester/create/`;
export const POINT_HISTORY_URL = `${API_URL}/point/history/`;
export const DELETE_POINT_ENTRY_URL = `${API_URL}/point/entry/`;
export const DELETE_ALL_POINT_ENTRIES_URL = `${API_URL}/point/entry/all`;
export const TUTORING_POINTS_CSV_URL = `${API_URL}/point/tutoring/from-csv/`;
export const BONUS_POINTS_CSV_URL = `${API_URL}/point/attendance-bonus/from-csv/`;

export const RESOURCE_URL = `${API_URL}/resource/`;
export const RESOURCE_FOLDER_URL = `${API_URL}/resource/folder/`;
export const RESOURCE_FOLDER_ITEM_URL = `${API_URL}/resource/folder-item/`;

export const PROFILE_URL = `${API_URL}/user/get/`;
export const PROFILE_UPDATE_URL = `${API_URL}/user/update/`;

export const SYSTEM_CONFIG_URL = `${API_URL}/system-config/`;
export const SYSTEM_CONFIG_UPDATE_URL = `${API_URL}/system-config/update/`;
export const SCHOLARSHIP_URL = `${API_URL}/student-opportunities/scholarship/`;
export const JOB_OPPORTTUNITIES_URL = `${API_URL}/student-opportunities/job-opportunity/`;
export const CAREER_COACHES_URL = `${API_URL}/student-opportunities/career-coach/`;
export const STUDENT_PUBLIC_PROFILE_URL = `${API_URL}/student-opportunities/public-profile/`;
export const FEEDBACK_LIST_URL = `${API_URL}/student-opportunities/feedback/list/`;
export const FEEDBACK_CREATE_URL = `${API_URL}/student-opportunities/feedback/create/`;
export const FEEDBACK_DELETE_URL = `${API_URL}/student-opportunities/feedback/`;
