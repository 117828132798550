import { DataQueryOptions, Results } from '@app/components/DataWrapper';
import { apiCall, apiCallVoid } from '@app/util/helpers';
import {
  BONUS_POINTS_CSV_URL,
  CREATE_SEMESTER_URL,
  DELETE_ALL_POINT_ENTRIES_URL,
  DELETE_POINT_ENTRY_URL,
  POINT_CONFIG_URL,
  POINT_HISTORY_URL,
  TUTORING_POINTS_CSV_URL,
  UPDATE_ACTIVE_SEMESTER_URL,
  UPDATE_POINT_CONFIG_ENTRIES_URL,
} from 'config';
import { EventData } from './eventService';

// -----------------------types-----------------------
export type PointConfigType =
  | 'LECTURE_ATTENDANCE_POINTS'
  | 'LECTURE_ATTENDANCE_WK_MAX'
  | 'BONUS_LECTURE_ATTENDANCE_POINTS'
  | 'TUTORING_ATTENDANCE_POINTS'
  | 'TUTORING_ATTENDANCE_WK_MAX'
  | 'EVENT_ATTENDANCE_POINTS'
  | 'EVENT_ATTENDANCE_SEM_MAX'
  | 'FINANCIAL_AID_BEFORE_START_POINTS'
  | 'FINANCIAL_AID_PHASE_1_POINTS'
  | 'FINANCIAL_AID_PHASE_2_POINTS'
  | 'STUDENT_PORTAL_LOGIN_POINTS'
  | 'STUDENT_PORTAL_SEM_MAX';

export interface PointConfigEntry {
  id: string;
  type: PointConfigType;
  score: number;
}

export interface Semester {
  id: string;
  start: string;
  end: string;
  isActive: boolean;
}

export interface PointConfig {
  pointConfigs: PointConfigEntry[];
  semesters: Semester[];
}

export type PointType =
  | 'LECTURE_ATTENDANCE'
  | 'BONUS_LECTURE_ATTENDANCE'
  | 'TUTORING_ATTENDANCE'
  | 'EVENT_ATTENDANCE'
  | 'FINANCIAL_AID'
  | 'STUDENT_PORTAL_LOGIN';

export interface PointHistoryEntry {
  id: string;
  timestamp: string;
  studentId: string;
  student: {
    customId: string;
    firstName: string;
    lastName: string;
    email: string;
    profilePicture?: string;
  };
  source: {
    type: PointType;
    semesterId: string;
    score: 0;
    lecture: EventData | null;
    event: EventData | null;
    tutoring: EventData | null;
  };
  // title is only used for filtering (values is always null)
  title?: string;
}

// -----------------------services-----------------------
interface Kwargs {
  token: string;
}
export async function getPointConfig({ token }: Kwargs): Promise<PointConfig> {
  return apiCall({
    url: POINT_CONFIG_URL,
    token,
  });
}
interface Kwargs1 extends Kwargs {
  entries: {
    [key: string]: number;
  };
}
export async function updatePointConfigEntries({
  token,
  entries,
}: Kwargs1): Promise<void> {
  return apiCallVoid({
    url: UPDATE_POINT_CONFIG_ENTRIES_URL,
    token,
    json: entries,
    successMessage: 'Point config updated',
  });
}

interface Kwargs2 extends Kwargs {
  id: string;
}
export async function setActiveSemester({ token, id }: Kwargs2): Promise<void> {
  return apiCallVoid({
    url: UPDATE_ACTIVE_SEMESTER_URL,
    token,
    json: { semesterId: id },
    successMessage: 'Active semester updated',
  });
}

interface Kwargs3 extends Kwargs {
  start: string;
  end: string;
}
export async function createSemester({
  token,
  start,
  end,
}: Kwargs3): Promise<void> {
  return apiCallVoid({
    url: CREATE_SEMESTER_URL,
    token,
    json: { start, end },
    successMessage: 'Semester created',
  });
}

interface Kwargs4 extends Kwargs {
  options: DataQueryOptions;
}
export async function getPointsHistory({
  token,
  options,
}: Kwargs4): Promise<Results<PointHistoryEntry>> {
  return apiCall({
    url: POINT_HISTORY_URL,
    token,
    json: options,
  });
}

interface Kwargs5 extends Kwargs {
  id: string;
}
export async function deletePointEntry({ token, id }: Kwargs5): Promise<void> {
  return apiCallVoid({
    method: 'DELETE',
    url: `${DELETE_POINT_ENTRY_URL}${id}`,
    token,
    successMessage: 'Point entry deleted',
  });
}

export async function deleteAllPointEntries({ token }: Kwargs): Promise<void> {
  return apiCallVoid({
    method: 'DELETE',
    url: DELETE_ALL_POINT_ENTRIES_URL,
    token,
    successMessage: 'All point entries deleted',
  });
}

interface Kwargs6 extends Kwargs {
  title: string;
  date: string;
  file: File;
}
export async function addTutorialPointsFromCSV({
  token,
  title,
  date,
  file,
}: Kwargs6): Promise<void> {
  const fileContent = await file.text();

  return apiCallVoid({
    url: TUTORING_POINTS_CSV_URL,
    token,
    json: { title, date, csv: fileContent },
    successMessage: 'Tutorial points added',
  });
}

interface Kwargs7 extends Kwargs {
  file: File;
}
export async function addBonusPointsFromCSV({
  token,
  file,
}: Kwargs7): Promise<void> {
  const fileContent = await file.text();

  return apiCallVoid({
    url: BONUS_POINTS_CSV_URL,
    token,
    json: { csv: fileContent },
    successMessage: 'Attendance Bonus points added',
  });
}
