import { ConfirmDeleteModal } from '@app/components/ConfirmDeleteModal';
import { GenericModal, useModal } from '@app/components/GenericModal';
import { useAuth } from '@app/hooks/useAuth';
import { deleteAllPointEntries } from '@app/services/pointService';
import { Delete, TableView } from '@mui/icons-material';
import { Button, Stack, Box } from '@mui/material';
import { AddBonusPointsFromCsvForm } from './AddBonusPointsFromCsvForm';
import { AddTutoringPointsFromCsvForm } from './AddTutoringPointsFromCsvForm';

export function Actions() {
  const { tokenData } = useAuth();
  const token = tokenData?.token || '';

  const {
    isOpen: isTutoringCsvModalOpen,
    openModal: csvTutoringModelOnOpen,
    closeModal: csvTutoringModalOnClose,
  } = useModal();

  const {
    isOpen: isBonusCsvModalOpen,
    openModal: csvBonusModelOnOpen,
    closeModal: csvBonusModalOnClose,
  } = useModal();

  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  return (
    <Box>
      <Stack direction="row" justifyContent="end" gap={2} mb={4}>
        <Button
          variant="outlined"
          startIcon={<TableView />}
          onClick={csvBonusModelOnOpen}
        >
          Attendance Bonus Points (CSV)
        </Button>
        <Button
          variant="outlined"
          startIcon={<TableView />}
          onClick={csvTutoringModelOnOpen}
        >
          Tutoring Points (CSV)
        </Button>
        <Button
          variant="contained"
          startIcon={<Delete />}
          onClick={openDeleteModal}
        >
          Delete All
        </Button>
      </Stack>
      <GenericModal
        title="Add Bonus Attendence Points From CSV"
        isOpen={isBonusCsvModalOpen}
        onClose={csvBonusModalOnClose}
        useOnlyCloseButton
      >
        <AddBonusPointsFromCsvForm onClose={csvBonusModalOnClose} />
      </GenericModal>
      <GenericModal
        title="Add Tutoring Points From CSV"
        isOpen={isTutoringCsvModalOpen}
        onClose={csvTutoringModalOnClose}
        useOnlyCloseButton
      >
        <AddTutoringPointsFromCsvForm onClose={csvTutoringModalOnClose} />
      </GenericModal>
      <ConfirmDeleteModal
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        text="Delete all point entries of current semester"
        deleteFn={() => deleteAllPointEntries({ token })}
        queryKey="points-history"
      />
    </Box>
  );
}
