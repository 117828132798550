/* eslint-disable unicorn/no-useless-undefined */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { Btn } from '@app/components/Btn';
import { useAuth } from '@app/hooks/useAuth';
import { addBonusPointsFromCSV } from '@app/services/pointService';
import { Typography, Stack, Button, Divider } from '@mui/material';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

interface Props {
  onClose: () => void;
}

//  Function to truncate displayed name string of selected file
function truncateFileName(fileName: string) {
  const fileType = fileName.split('.')[fileName.split('.').length - 1];
  const fileNameWithoutType = fileName.split('.')[0];
  const maxNumberOfChars = 20;
  if (fileNameWithoutType.length > maxNumberOfChars) {
    return `${fileNameWithoutType.slice(0, maxNumberOfChars)}...${fileType}`;
  }
  return fileName;
}

export function AddBonusPointsFromCsvForm(props: Props) {
  const { onClose } = props;
  const [error, setError] = useState<string>();

  const [selectedFile, setSelectedFile] = useState<File>();

  const { tokenData } = useAuth();
  const token = tokenData?.token || '';
  const queryClient = useQueryClient();
  const mutation = useMutation(addBonusPointsFromCSV, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('points-history');
      onClose();
    },
  });

  //  Handler function for handling file input
  const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setSelectedFile(fileList[0]);
  };

  const submitFile = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    if (selectedFile) {
      mutation.mutate({
        token,
        file: selectedFile,
      });
    } else {
      setError('Please select a file');
    }
  };

  return (
    <form onSubmit={submitFile}>
      <Stack spacing={4} pt={2}>
        <div>
          <Typography variant="body1" fontWeight="bold">
            Upload a CSV file of bonus attendance points
          </Typography>
          <Typography variant="body1" color="red" gutterBottom>
            Important! Following Columns are required
            <br /> <b>Id</b>
          </Typography>
          <Stack
            direction="row"
            justifyContent="start"
            spacing={2}
            alignContent="center"
          >
            <Button
              variant="outlined"
              color="primary"
              sx={{ fontWeight: 'bold' }}
              component="label"
            >
              Choose File
              <input
                accept=".csv"
                style={{ display: 'none' }}
                id="csv-file"
                type="file"
                multiple={false}
                onChange={handleFileSelection}
              />
            </Button>
            <Typography variant="body1" color="gray">
              {selectedFile
                ? truncateFileName(selectedFile.name)
                : 'No file uploaded yet'}
            </Typography>
          </Stack>
        </div>
        {error ||
          (mutation.isError && (
            <Typography color="error">
              *{error || (mutation.error as Error)?.message}
            </Typography>
          ))}
        <Divider />
      </Stack>

      <Stack direction="row" justifyContent="end" spacing={2} mt={2}>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Close
        </Button>
        <Btn
          variant="contained"
          color="primary"
          type="submit"
          isLoading={mutation.isLoading}
        >
          Add Points
        </Btn>
      </Stack>
    </form>
  );
}
