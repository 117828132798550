import { GenericModal, useModal } from '@app/components/GenericModal';
import { Add } from '@mui/icons-material';
import { Button, Stack, Box } from '@mui/material';
import { AddForm } from './AddForm';

export function Actions() {
  const { isOpen, closeModal, openModal } = useModal();

  return (
    <Box>
      <Stack direction="row" justifyContent="end" gap={2} mb={4}>
        <Button variant="contained" startIcon={<Add />} onClick={openModal}>
          Professor
        </Button>
      </Stack>

      <GenericModal
        title="Add Staff"
        isOpen={isOpen}
        onClose={closeModal}
        useOnlyCloseButton
        width={600}
      >
        <AddForm onClose={closeModal} />
      </GenericModal>
    </Box>
  );
}
