import { DataQueryOptions, Results } from '@app/components/DataWrapper';
import { apiCall, apiCallVoid, getFormData } from '@app/util/helpers';
import {
  STUDENT_ALL_URL,
  STUDENT_CREATE_URL,
  STUDENT_CSV_CREATE_URL,
  STUDENT_DEGREE_AUDIT_CSV_URL,
  STUDENT_DELETE_URL,
  STUDENT_EDIT_URL,
  STUDENT_RESEND_INVITE_URL,
} from 'config';

export interface StudentData {
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  profilePicture?: string;
  phone?: string;
  degreeAudit?: string;
  customId: string;
  id: string;
  profile?: {
    id: string;
  };
  hasLogged: boolean;
}

export async function getAllStudents(
  token: string,
  options: DataQueryOptions,
): Promise<Results<StudentData>> {
  return apiCall({
    url: STUDENT_ALL_URL,
    token,
    json: options,
  });
}

export interface StudentCreateSuccess {
  id: string;
  email: string;
  created?: boolean;
  updated?: boolean;
}
export async function createStudentsFromCSV({
  token,
  file,
}: {
  token: string;
  file: File;
}): Promise<StudentCreateSuccess[]> {
  const fileContent = await file.text();
  return apiCall({
    url: STUDENT_CSV_CREATE_URL,
    token,
    formData: getFormData({ csv: fileContent }),
  });
}

interface CreateStudentOptions {
  token: string;
  firstName: string;
  lastName: string;
  email: string;
  customId: string;
  phone: string | undefined;
  degreeAudit: string | undefined;
}
export async function createStudent({
  token,
  ...body
}: CreateStudentOptions): Promise<StudentCreateSuccess> {
  return apiCall({
    url: STUDENT_CREATE_URL,
    token,
    json: body,
    successMessage: 'Students created successfully',
  });
}

interface EditStudentOptions {
  token: string;
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  customId: string;
  phone: string | undefined;
  degreeAudit: string | undefined;
}
export async function editStudent({
  token,
  id,
  ...body
}: EditStudentOptions): Promise<void> {
  return apiCallVoid({
    url: `${STUDENT_EDIT_URL}${id}`,
    token,
    formData: getFormData(body, true),
    successMessage: 'Student updated successfully',
  });
}

interface DeleteStudentOptions {
  token: string;
  id: string;
}
export async function deleteStudent({
  token,
  id,
}: DeleteStudentOptions): Promise<void> {
  return apiCallVoid({
    url: `${STUDENT_DELETE_URL}${id}`,
    token,
    method: 'DELETE',
    successMessage: 'Student deleted successfully',
  });
}

export async function addDegreeAuditsFromCSV({
  token,
  file,
}: {
  token: string;
  file: File;
}): Promise<void> {
  const fileContent = await file.text();
  return apiCallVoid({
    url: STUDENT_DEGREE_AUDIT_CSV_URL,
    token,
    json: { csv: fileContent },
    successMessage: 'Degree audit added successfully',
  });
}

export async function resendInvite({
  token,
  id,
}: {
  token: string;
  id: string;
}): Promise<void> {
  return apiCallVoid({
    url: STUDENT_RESEND_INVITE_URL + id,
    method: 'POST',
    token,
    successMessage: 'Invite send successfully',
  });
}
