import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { useAuth } from '@app/hooks/useAuth';
import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FolderItemsListActions } from './FolderItemsListActions';
import { FolderItemListView } from './FolderItemsListView';

interface Props {
  queryKey: string;
  linkPrefix: string;
  title: string;
}

export function FolderItemsPage({ queryKey, linkPrefix, title }: Props) {
  const { isAdmin } = useAuth();
  const { folderName = '', folderId = '' } = useParams();

  return (
    <Layout>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BreadCrumbs
          links={[
            {
              label: 'Dashboard',
              link: '/',
            },
            {
              label: title,
              link: linkPrefix,
            },
            {
              label: folderName,
              link: '',
            },
          ]}
        />
        {isAdmin && (
          <FolderItemsListActions queryKey={queryKey} folderId={folderId} />
        )}
      </Stack>

      <FolderItemListView
        queryKey={queryKey}
        title={title}
        folderId={folderId}
        folderName={folderName}
      />
    </Layout>
  );
}
