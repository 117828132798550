import { Btn } from '@app/components/Btn';
import { useAuth } from '@app/hooks/useAuth';
import {
  getSystemConfig,
  updateSystemConfig,
} from '@app/services/settingsService';
import {
  Container,
  List,
  ListItem,
  ListItemText,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

interface State {
  [key: string]: {
    id: string;
    value: boolean;
  };
}

export function HomePageComponents() {
  const { token } = useAuth();
  const [config, setConfig] = useState<State>({});
  const query = useQuery('settings', () => getSystemConfig(token || ''), {
    onSuccess: data => {
      const items: State = {};
      data.forEach(entry => {
        items[entry.type] = {
          id: entry.id,
          value: entry.value === 'true',
        };
      });
      setConfig(items);
    },
    refetchOnWindowFocus: false,
  });

  const onChange =
    (type: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setConfig({
        ...config,
        [type]: {
          ...config[type],
          value: event.target.checked,
        },
      });
    };

  const queryClient = useQueryClient();
  const mutation = useMutation(updateSystemConfig, {
    onSuccess: () => queryClient.invalidateQueries('settings'),
  });

  const onSubmit = () => {
    mutation.mutate({
      token: token || '',
      items: Object.fromEntries(
        Object.values(config).map(item => [item.id, String(item.value)]),
      ),
    });
  };

  return (
    <div>
      <Typography variant="h6" fontWeight="bold">
        Home Page Components Visibility
      </Typography>

      <Container disableGutters maxWidth="xs" sx={{ mx: 0 }}>
        <List>
          <ListItem
            secondaryAction={
              <Switch
                onChange={onChange('ORIENTATION_VISIBLE')}
                checked={config.ORIENTATION_VISIBLE?.value || false}
              />
            }
          >
            <ListItemText>Orientation</ListItemText>
          </ListItem>
          <ListItem
            secondaryAction={
              <Switch
                onChange={onChange('MEET_YOUR_PROFESSORS_VISIBLE')}
                checked={config.MEET_YOUR_PROFESSORS_VISIBLE?.value || false}
              />
            }
          >
            <ListItemText>Meet Your Professors</ListItemText>
          </ListItem>
          <ListItem
            secondaryAction={
              <Switch
                onChange={onChange('EVENTS_AND_WORKSHOPS_VISIBLE')}
                checked={config.EVENTS_AND_WORKSHOPS_VISIBLE?.value || false}
              />
            }
          >
            <ListItemText>Events & Workshops</ListItemText>
          </ListItem>

          <ListItem
            secondaryAction={
              <Switch
                onChange={onChange('BUSINESS_LIBRARY_VISIBLE')}
                checked={config.BUSINESS_LIBRARY_VISIBLE?.value || false}
              />
            }
          >
            <ListItemText>Business Library</ListItemText>
          </ListItem>
          <ListItem
            secondaryAction={
              <Switch
                onChange={onChange('FINANCIAL_LITERACY_VISIBLE')}
                checked={config.FINANCIAL_LITERACY_VISIBLE?.value || false}
              />
            }
          >
            <ListItemText>Financial Literacy</ListItemText>
          </ListItem>
          <ListItem
            secondaryAction={
              <Switch
                onChange={onChange('SBT_POINTS_VISIBLE')}
                checked={config.SBT_POINTS_VISIBLE?.value || false}
              />
            }
          >
            <ListItemText>SBT Points</ListItemText>
          </ListItem>

          <ListItem
            secondaryAction={
              <Switch
                onChange={onChange('MY_DEGREE_AUDIT_VISIBLE')}
                checked={config.MY_DEGREE_AUDIT_VISIBLE?.value || false}
              />
            }
          >
            <ListItemText>My Degree Audit</ListItemText>
          </ListItem>
          <ListItem
            secondaryAction={
              <Switch
                onChange={onChange('STUDNET_OPPORTUNITY_VISIBLE')}
                checked={config.STUDNET_OPPORTUNITY_VISIBLE?.value || false}
              />
            }
          >
            <ListItemText>Student Opportunities</ListItemText>
          </ListItem>
          <ListItem
            secondaryAction={
              <Switch
                onChange={onChange('INSTITUTIONAL_RESOURCE_VISIBLE')}
                checked={config.INSTITUTIONAL_RESOURCE_VISIBLE?.value || false}
              />
            }
          >
            <ListItemText>Institutional Resources</ListItemText>
          </ListItem>
        </List>

        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <Btn
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={query.isLoading}
            isLoading={mutation.isLoading}
          >
            Save
          </Btn>
        </Stack>
      </Container>
    </div>
  );
}
