/* eslint-disable @typescript-eslint/no-floating-promises */
import { Btn } from '@app/components/Btn';
import {
  createScholarship,
  CreateScholarshipOptions,
  editScholarship,
  ScholarshipData,
} from '@app/services/studentOpportunitiesService';
import {
  formatInputDateTime,
  getListFromString,
  getStringFromList,
} from '@app/util/helpers';
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { theme } from '@theme/theme';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

function getStyles(name: string, value: readonly string[]) {
  return {
    fontWeight: !value.includes(name)
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  };
}

interface Props {
  token: string;
  onClose: () => void;
  id?: string;
  defaultValues?: ScholarshipData;
}
export function Form({ token, onClose, id, defaultValues }: Props) {
  const [values, setValues] = useState({
    title: defaultValues?.title || '',
    description: defaultValues?.description || '',
    openDate: defaultValues?.openDate
      ? formatInputDateTime(defaultValues.openDate)
      : '',
    closeDate: defaultValues?.closeDate
      ? formatInputDateTime(defaultValues.closeDate)
      : '',
    link: defaultValues?.link || '',
    minimumGpa: defaultValues?.minimumGpa || 0,
    ethnicity: getListFromString(defaultValues?.ethnicity),
    visaCategory: getListFromString(defaultValues?.visaCategory),
    academicClassification: getListFromString(
      defaultValues?.academicClassification,
    ),
    genderType: getListFromString(defaultValues?.genderType),
  });

  const handleChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues(prev => ({ ...prev, [field]: event.target.value }));
    };

  const handleListChange =
    (field: string) => (event: SelectChangeEvent<string[]>) => {
      const {
        target: { value },
      } = event;
      setValues(prev => ({
        ...prev,
        [field]: typeof value === 'string' ? value.split(',') : value,
      }));
    };

  const queryClient = useQueryClient();
  const addMutation = useMutation(createScholarship, {
    onSuccess: () => {
      queryClient.invalidateQueries('scholarships');
      onClose();
    },
  });

  const editMutation = useMutation(editScholarship, {
    onSuccess: () => {
      queryClient.invalidateQueries('scholarships');
      onClose();
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data: CreateScholarshipOptions = {
      token,
      ...values,
      ethnicity: getStringFromList(values.ethnicity),
      visaCategory: getStringFromList(values.visaCategory),
      academicClassification: getStringFromList(values.academicClassification),
      genderType: getStringFromList(values.genderType),
    };
    if (id) {
      editMutation.mutate({ id, ...data });
    } else {
      addMutation.mutate({ ...data });
    }
  };

  const error = id ? editMutation.error : addMutation.error;
  const isLoading = editMutation.isLoading || addMutation.isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4} pt={2}>
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Title"
          value={values.title}
          onChange={handleChange('title')}
        />
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Description"
          value={values.description}
          onChange={handleChange('description')}
          multiline
          rows={3}
        />
        <Stack direction="row" spacing={4}>
          <TextField
            fullWidth
            required
            type="datetime-local"
            size="small"
            label="Open Date (include time)"
            value={values.openDate}
            onChange={handleChange('openDate')}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            fullWidth
            required
            type="datetime-local"
            size="small"
            label="Close Date (include time)"
            value={values.closeDate}
            onChange={handleChange('closeDate')}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: { min: values.openDate },
            }}
          />
        </Stack>
        <TextField
          fullWidth
          required
          type="url"
          size="small"
          label="Link"
          value={values.link}
          onChange={handleChange('link')}
        />
        <TextField
          fullWidth
          type="number"
          size="small"
          label="Minimun GPA"
          value={values.minimumGpa}
          onChange={handleChange('minimumGpa')}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Stack direction="row" spacing={4}>
          <FormControl sx={{ width: 1 }}>
            <InputLabel id="ethnicity">Ethnicity</InputLabel>
            <Select
              labelId="ethnicity"
              multiple
              value={values.ethnicity}
              onChange={handleListChange('ethnicity')}
              input={<OutlinedInput label="Ethnicity" />}
              renderValue={selected => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map(value => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {['Black', 'Hispanic', 'Asian Pacific Islander', 'Asian'].map(
                name => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, values.ethnicity)}
                  >
                    {name}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>

          <FormControl sx={{ width: 1 }}>
            <InputLabel id="visaCategory">Visa Category</InputLabel>
            <Select
              labelId="visaCategory"
              multiple
              value={values.visaCategory}
              onChange={handleListChange('visaCategory')}
              input={<OutlinedInput label="Visa Category" />}
              renderValue={selected => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map(value => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {['F-1 Student Visa', 'Permanent Resident', 'US Citizen'].map(
                name => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, values.visaCategory)}
                  >
                    {name}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
        </Stack>

        <Stack direction="row" spacing={4}>
          <FormControl sx={{ width: 1 }}>
            <InputLabel id="academicClassification">
              Accedemic Classification
            </InputLabel>
            <Select
              labelId="academicClassification"
              multiple
              value={values.academicClassification}
              onChange={handleListChange('academicClassification')}
              input={<OutlinedInput label="Accedemic Classification" />}
              renderValue={selected => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map(value => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {[
                'Freshman',
                'Sophomore',
                'Junior',
                'Senior',
                'Graduating Senior',
              ].map(name => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, values.academicClassification)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 1 }}>
            <InputLabel id="genderType">Gender Type</InputLabel>
            <Select
              labelId="genderType"
              multiple
              value={values.genderType}
              onChange={handleListChange('genderType')}
              input={<OutlinedInput label="Gender Type" />}
              renderValue={selected => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map(value => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {['Male', 'Female', 'Other'].map(name => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, values.academicClassification)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        {error && (
          <Typography color="error">{(error as Error).message}</Typography>
        )}
        <Stack direction="row" justifyContent="end" width={1} spacing={2}>
          <Button variant="outlined" color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Btn
            variant="contained"
            color="primary"
            type="submit"
            isLoading={isLoading}
          >
            {id ? 'Update' : 'Create'}
          </Btn>
        </Stack>
      </Stack>
    </form>
  );
}
