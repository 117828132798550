import { DataWrapper } from '@app/components/DataWrapper';
import { useAuth } from '@app/hooks/useAuth';
import {
  ResourceFolderItemData,
  getResourceFolderItems,
} from '@app/services/otherContentService';
import { ChevronRight, InfoOutlined } from '@mui/icons-material';
import { Alert, Grid, Stack, Typography } from '@mui/material';
import { VideoResourceCard } from './VideoResourceCard';
import { FolderItemsListItemActions } from './FolderItemsListItemActions';
import { ArticleResourceCard } from './ArticleResourceCard';

interface Props {
  title: string;
  folderId: string;
  folderName: string;
  queryKey: string;
}
export function FolderItemListView({
  title,
  folderName,
  folderId,
  queryKey,
}: Props) {
  const { isAdmin } = useAuth();

  return (
    <DataWrapper<ResourceFolderItemData>
      id={queryKey}
      filterConfig={[]}
      fetchData={data => getResourceFolderItems({ ...data, folderId })}
      render={data =>
        data.length === 0 ? (
          <Alert
            severity="warning"
            icon={<InfoOutlined color="primary" />}
            sx={{
              justifyContent: 'center',
              color: '#7E0000',
              fontWeight: 'bold',
            }}
          >
            No Records Found !
          </Alert>
        ) : (
          <Grid container spacing={4}>
            {data.map(row => (
              <Grid key={row.id} item xs={12} md={12} lg={6} xl={4}>
                {row.youtubeUrl ? (
                  <VideoResourceCard
                    title={row.title}
                    url={row.youtubeUrl || ''}
                    image={row.image}
                    actions={
                      isAdmin && (
                        <FolderItemsListItemActions
                          data={row}
                          queryKey={queryKey}
                        />
                      )
                    }
                    location={
                      <Stack direction="row" gap={1} color="secondary.main">
                        <Typography>{title}</Typography>
                        <ChevronRight />
                        <Typography fontWeight="bold">{folderName}</Typography>
                      </Stack>
                    }
                  />
                ) : (
                  <ArticleResourceCard
                    title={row.title}
                    description={row.description || ''}
                    url={row.url || ''}
                    image={row.image}
                    actions={
                      isAdmin && (
                        <FolderItemsListItemActions
                          data={row}
                          queryKey={queryKey}
                        />
                      )
                    }
                    location={
                      <Stack direction="row" gap={1} color="secondary.main">
                        <Typography>{title}</Typography>
                        <ChevronRight />
                        <Typography fontWeight="bold">{folderName}</Typography>
                      </Stack>
                    }
                  />
                )}
              </Grid>
            ))}
          </Grid>
        )
      }
    />
  );
}
