import { BasicLayout } from '@app/components/Layout';
import { forgotPassword } from '@app/services/authService';
import { CheckCircleOutlined, Email } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Container,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useMutation } from 'react-query';
import loginImg from '@assets/login-img.png';
import { Btn } from '@app/components/Btn';

export function ForgotPassword() {
  const [email, setEmail] = useState<string>('');
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const mutation = useMutation(forgotPassword);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email) {
      mutation.mutate({ email });
    }
  };

  return (
    <BasicLayout>
      <Stack alignItems="center">
        <Container maxWidth="xs">
          <Box
            component="img"
            src={loginImg}
            alt="SBT"
            sx={{ maxWidth: 1, objectFit: 'contain' }}
          />

          <Typography variant="h4" fontWeight="bold" align="center">
            Forgot Password
          </Typography>
          <Typography align="center" mb={2}>
            Email will be sent to you with a link to reset your password.
          </Typography>

          <Card variant="outlined" sx={{ p: 2 }}>
            {mutation.isSuccess ? (
              <Stack alignItems="center">
                <CheckCircleOutlined color="success" fontSize="large" />
                <Typography color="green" align="center">
                  An email has been sent to you with a link to reset your
                  password.
                </Typography>
              </Stack>
            ) : (
              <form onSubmit={onSubmit}>
                <Stack component={CardContent} alignItems="center" spacing={2}>
                  <TextField
                    placeholder="Email"
                    type="email"
                    value={email}
                    onChange={onChangeEmail}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email />
                        </InputAdornment>
                      ),
                    }}
                    size="small"
                    fullWidth
                    required
                  />

                  {mutation.isError && (
                    <Typography color="error" align="left" width={1}>
                      *{(mutation.error as Error).message}
                    </Typography>
                  )}

                  <Btn
                    variant="contained"
                    type="submit"
                    isLoading={mutation.isLoading}
                  >
                    Submit
                  </Btn>
                </Stack>
              </form>
            )}
          </Card>
        </Container>
      </Stack>
    </BasicLayout>
  );
}
