import { GenericModal, useModal } from '@app/components/GenericModal';
import { usePointConfig } from '@app/hooks/usePointConfig';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';

function CreateSemesterForm({ onClose }: { onClose: () => void }) {
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');

  const { createNewSemester, semesterCreateError } = usePointConfig({
    onSemesterCreateSuccess: onClose,
  });

  const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    e.currentTarget.checkValidity();
    if (start && end) {
      createNewSemester(start, end);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Stack gap={2}>
        <TextField
          type="date"
          value={start}
          onChange={e => setStart(e.target.value)}
          label="Start"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          required
        />
        <TextField
          type="date"
          value={end}
          onChange={e => setEnd(e.target.value)}
          label="End"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          required
        />
        {semesterCreateError && (
          <Typography color="error">{semesterCreateError}</Typography>
        )}
        <Stack direction="row" justifyContent="flex-end" gap={2}>
          <Button variant="outlined" color="primary" onClick={onClose}>
            Close
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Create
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

export function CreateSemester() {
  const { isOpen, closeModal, openModal } = useModal();

  return (
    <>
      <Button variant="contained" color="secondary" onClick={openModal}>
        Create Semester
      </Button>
      <GenericModal
        isOpen={isOpen}
        onClose={closeModal}
        title="Create Semester"
        useOnlyCloseButton
      >
        <CreateSemesterForm onClose={closeModal} />
      </GenericModal>
    </>
  );
}
