import { DataWrapper } from '@app/components/DataWrapper';
import {
  getAllJobOpportunities,
  JobOpportunityData,
} from '@app/services/studentOpportunitiesService';
import { Card, Stack } from '@mui/material';
import { TableRowActions } from './TableRowActions';
import { OpportunityCard } from './OpportunityCard';

export function Table() {
  return (
    <DataWrapper<JobOpportunityData>
      id="jobOpportunities"
      filterConfig={[]}
      fetchData={getAllJobOpportunities}
      render={data => (
        <Stack gap={2}>
          {data.map((item, index) => (
            <Card key={item.id}>
              <Stack>
                <OpportunityCard
                  index={index}
                  title={item.title}
                  deadlineDate={item.closeDate}
                  openDate={item.openDate}
                  description={item.description}
                  link={item.link}
                  company={item.company}
                  visaCategory={item.visaCategory}
                  employeeType={item.employmentType}
                  academicClassifications={item.academicClassification}
                >
                  <TableRowActions data={item} />
                </OpportunityCard>
              </Stack>
            </Card>
          ))}
        </Stack>
      )}
    />
  );
}
