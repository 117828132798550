import { DataWrapper } from '@app/components/DataWrapper';
import {
  Feedback,
  listFeedback,
} from '@app/services/studentOpportunitiesService';
import { Stack } from '@mui/material';
import { formatDate, formatTime } from '@app/util/helpers';
import { FeedbackCard } from './FeedbackCard';
import { FeedbackActions } from './FeedbackActions';

export function FeedbackList() {
  return (
    <DataWrapper<Feedback>
      id="feedbacks"
      filterConfig={[]}
      fetchData={listFeedback}
      render={data => (
        <Stack gap={2}>
          {data &&
            data.map((item, index) => (
              <Stack key={item.id}>
                <FeedbackCard
                  index={index}
                  name={`${item.lecturer.firstName} ${item.lecturer.lastName}`}
                  studentName={`${item.student.firstName} ${item.student.lastName}`}
                  topic={item.topic}
                  description={item.description}
                  date={formatDate(item.meetingDate)}
                  time={formatTime(item.meetingTime)}
                  link={item.lecturer.scheduleMeeting}
                >
                  <FeedbackActions data={item} />
                </FeedbackCard>
              </Stack>
            ))}
        </Stack>
      )}
    />
  );
}
