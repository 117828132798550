import { DataWrapper } from '@app/components/DataWrapper';
import { ResourceFolderCard } from '@app/components/ResourceFolderCard';
import { useAuth } from '@app/hooks/useAuth';
import {
  ResourceFolderData,
  getResourceFolders,
  IResourceType,
} from '@app/services/otherContentService';
import { InfoOutlined } from '@mui/icons-material';
import { Alert, Grid } from '@mui/material';
import { FolderListItemActions } from './FolderListItemActions';

interface Props {
  queryKey: string;
  folderType: IResourceType;
  linkPrefix: string;
}

export function FolderListView({ queryKey, folderType, linkPrefix }: Props) {
  const { isAdmin } = useAuth();

  return (
    <DataWrapper<ResourceFolderData>
      id={queryKey}
      filterConfig={[]}
      fetchData={options =>
        getResourceFolders({ ...options, type: folderType })
      }
      render={data =>
        data.length === 0 ? (
          <Alert
            severity="warning"
            icon={<InfoOutlined color="primary" />}
            sx={{
              justifyContent: 'center',
              color: '#7E0000',
              fontWeight: 'bold',
            }}
          >
            No Records Found !
          </Alert>
        ) : (
          <Grid container spacing={4}>
            {data.map((row, index) => (
              <Grid key={row.id} item xs={12} md={12} lg={6} xl={4}>
                <ResourceFolderCard
                  index={index}
                  title={row.title}
                  id={row.id}
                  image={row.image}
                  linkPrefix={linkPrefix}
                  actions={
                    isAdmin && (
                      <FolderListItemActions data={row} queryKey={queryKey} />
                    )
                  }
                />
              </Grid>
            ))}
          </Grid>
        )
      }
    />
  );
}
