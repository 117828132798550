/* eslint-disable @typescript-eslint/no-floating-promises */
import { Btn } from '@app/components/Btn';
import { useProfile } from '@app/hooks/useProfile';
import { Edit } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

export function ProfileInfo() {
  const [error, setError] = useState<string>();
  const [editing, setEditing] = useState(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');

  const { data, updateProfile, updateProfileIsLoading } = useProfile({
    onUpdateSuccess: () => {
      setEditing(false);
      setError('');
    },
    onUpdateError: (err: Error) => setError(err.message),
  });

  const startEditing = () => setEditing(true);
  const cancelEditing = () => {
    setEditing(false);
    setFirstName(data?.firstName || '');
    setLastName(data?.lastName || '');
  };

  const onSave = () => {
    if (firstName) {
      updateProfile({ firstName, lastName });
    }
  };

  useEffect(() => {
    if (data) {
      setFirstName(data.firstName);
      setLastName(data.lastName);
    }
  }, [data]);

  return (
    <Card variant="outlined">
      <CardContent sx={{ p: 4 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6" fontWeight="bold" mb={2}>
            Profile Info
          </Typography>

          <IconButton onClick={startEditing}>
            <Edit />
          </IconButton>
        </Stack>

        {data ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label="First Name"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                InputProps={{
                  readOnly: !editing,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label="Last Name"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                InputProps={{
                  readOnly: !editing,
                }}
              />
            </Grid>
            {editing && (
              <>
                {error && (
                  <Grid item xs={12}>
                    <Typography variant="caption" color="error">
                      *{error}
                    </Typography>
                  </Grid>
                )}
                <Grid component={Stack} item xs={12} justifyContent="end">
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={cancelEditing}
                    sx={{ mr: 1 }}
                  >
                    Cancel
                  </Button>
                  <Btn
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={onSave}
                    isLoading={updateProfileIsLoading}
                  >
                    Save
                  </Btn>
                </Grid>
                <Grid item xs={12} mb={2} />
              </>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label="Email"
                value={data.email}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <Stack alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}
