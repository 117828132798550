import { DataWrapper } from '@app/components/DataWrapper';
import { GenericTable, TableHeader } from '@app/components/GenericTable';
import { getAllTutorings, TutoringData } from '@app/services/eventService';
import { formatDate, formatTime } from '@app/util/helpers';
import { TableRowActions } from './TableRowActions';

const headers: TableHeader<TutoringData>[] = [
  {
    headerLabel: 'Title',
    isSortable: true,
    key: 'name',
  },
  {
    headerLabel: 'Description',
    isSortable: false,
    key: 'description',
    renderCell: row =>
      row.description.length > 80
        ? `${row.description.slice(0, 80)}...`
        : row.description,
  },
  {
    headerLabel: 'Date',
    isSortable: true,
    key: 'startDate',
    renderCell: row => formatDate(row.startDate),
  },
  {
    headerLabel: 'Time',
    isSortable: false,
    key: 'startDate',
    renderCell: row =>
      `${formatTime(row.startDate)} - ${formatTime(row.endDate)}`,
  },
  {
    headerLabel: 'Actions',
    key: 'id',
    isSortable: false,
    renderCell: row => <TableRowActions data={row} />,
  },
];

export function Table() {
  return (
    <DataWrapper<TutoringData>
      id="tutorings"
      filterConfig={[
        {
          field: 'name',
          label: 'Title',
          type: 'contains',
          value: '',
          isCaseInsensitive: true,
        },
      ]}
      fetchData={getAllTutorings}
      render={(data, { sortBy, setSortBy }) => (
        <GenericTable
          tableHeaders={headers}
          data={data}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
      )}
    />
  );
}
