/* eslint-disable @typescript-eslint/no-floating-promises */
import { Btn } from '@app/components/Btn';
import { useAuth } from '@app/hooks/useAuth';
import { createFeedback } from '@app/services/studentOpportunitiesService';
import {
  Typography,
  Grid,
  TextField,
  Stack,
  Button,
  MenuItem,
} from '@mui/material';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

interface Props {
  onClose: () => void;
  studentId: string;
}

export function FeedbackForm(props: Props) {
  const { onClose, studentId } = props;

  const { token = '' } = useAuth();

  const [error, setError] = useState<string>();

  const [topic, setTopic] = useState('Review');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');

  const queryClient = useQueryClient();
  const mutation = useMutation(createFeedback, {
    onSuccess: () => {
      queryClient.invalidateQueries('students');
      onClose();
    },
    onError: (err: Error) => {
      setError(err.message);
    },
  });

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (studentId) {
      mutation.mutate({
        token,
        studentId,
        topic,
        description,
        meetingDate: date,
        meetingTime: date,
      });
    }
  };

  return (
    <form onSubmit={submitForm}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            required
            size="small"
            label="Topic"
            value={topic}
            onChange={e => setTopic(e.target.value)}
          >
            <MenuItem value="Resume Review">Resume Review</MenuItem>
            <MenuItem value="Internship Search">Internship Search</MenuItem>
            <MenuItem value="Job Search">Job Search</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            fullWidth
            required
            type="text"
            size="small"
            label="Description"
            value={description}
            onChange={e => setDescription(e.target.value)}
            minRows={5}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="datetime-local"
            size="small"
            label="Meeting Date & Time"
            value={date}
            onChange={e => setDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        {error && (
          <Grid item xs={12}>
            <Typography color="error">{error}</Typography>
          </Grid>
        )}
      </Grid>

      <Stack direction="row" justifyContent="end" width={1} mt={2}>
        <Button
          variant="outlined"
          color="primary"
          sx={{ mr: 1 }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Btn
          variant="contained"
          color="primary"
          type="submit"
          isLoading={mutation.isLoading}
        >
          Save
        </Btn>
      </Stack>
    </form>
  );
}
