import { useIsMobile } from '@app/hooks/useIsMobile';
import { ISectionDetails } from '@app/services/studentOpportunitiesService';

import { Avatar, Box, Stack, Typography } from '@mui/material';

export interface Props {
  title: string;
  content: ISectionDetails[];
  background: string;
}

export function ContentSection({ title, content, background }: Props) {
  return (
    <Box bgcolor={`${background}`} p={4}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" fontWeight="bold" mb={4}>
          {title}
        </Typography>
      </Stack>
      <Stack gap={4}>
        {content.map(c => (
          <ContentRow key={c.id} data={c} />
        ))}
      </Stack>
    </Box>
  );
}

function ContentRow({ data }: { data: ISectionDetails }) {
  const isMobile = useIsMobile();

  return (
    <Box
      p={0}
      borderRadius={4}
      sx={{
        '@media print': {
          breakInside: 'avoid',
        },
      }}
    >
      <Stack direction="row" gap={2}>
        {data.image && (
          <Box>
            <Avatar
              src={data.image}
              sx={{ width: 80, borderRadius: '100%', height: 80 }}
            />
          </Box>
        )}
        {!data.image && (
          <Box
            height={80}
            width={80}
            borderRadius={80}
            bgcolor="red"
            flexShrink={0}
          />
        )}

        {data && (
          <Box flexGrow={1}>
            <Stack
              direction={`${isMobile ? 'column' : 'row'}`}
              gap={`${isMobile ? '1' : '8'}`}
              justifyContent={`${isMobile ? 'left' : 'space-between'}`}
            >
              <Typography>{data.title}</Typography>
              {data.timePeriod && <Typography>{data.timePeriod}</Typography>}
            </Stack>
            {data.body && (
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  maxWidth: '100%', // Set the maximum width of the container
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                }}
              >
                <Typography mt={1}>{data.body}</Typography>
              </Stack>
            )}
          </Box>
        )}
      </Stack>
    </Box>
  );
}
