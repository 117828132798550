import { DataQueryOptions, Results } from '@app/components/DataWrapper';
import { apiCall, apiCallVoid } from '@app/util/helpers';
import {
  FEEDBACK_CREATE_URL,
  FEEDBACK_DELETE_URL,
  FEEDBACK_LIST_URL,
  JOB_OPPORTTUNITIES_URL,
  SCHOLARSHIP_URL,
  STUDENT_PUBLIC_PROFILE_URL,
} from 'config';

export interface ScholarshipData {
  id: string;
  title: string;
  description: string;
  openDate: string;
  closeDate: string;
  link: string;
  minimumGpa?: number;
  ethnicity?: string;
  visaCategory?: string;
  academicClassification?: string;
  genderType?: string;
  createdAt: string;
  updatedAt: string;
}

interface Kwargs {
  token: string;
  options: DataQueryOptions;
}
export async function getAllScholarships({
  token,
  options,
}: Kwargs): Promise<Results<ScholarshipData>> {
  return apiCall({
    url: `${SCHOLARSHIP_URL}list`,
    token,
    json: options,
  });
}
export interface CreateScholarshipOptions {
  token: string;
  title: string;
  description: string;
  openDate: string;
  closeDate: string;
  link: string;
  minimumGpa: number;
  ethnicity: string;
  visaCategory: string;
  academicClassification: string;
  genderType: string;
}

export async function createScholarship({
  token,
  ...data
}: CreateScholarshipOptions): Promise<void> {
  return apiCallVoid({
    url: `${SCHOLARSHIP_URL}create`,
    token,
    json: data,
    successMessage: 'Scholarship created successfully',
  });
}

export interface EditScholashipOptions extends CreateScholarshipOptions {
  id: string;
}

export async function editScholarship({
  token,
  id,
  ...data
}: EditScholashipOptions): Promise<void> {
  return apiCallVoid({
    url: `${SCHOLARSHIP_URL}${id}`,
    method: 'PUT',
    token,
    json: data,
    successMessage: 'Scholarship updated successfully',
  });
}

interface DeleteOptions {
  token: string;
  id: string;
}

export async function deleteScholarship({
  token,
  id,
}: DeleteOptions): Promise<void> {
  return apiCallVoid({
    token,
    url: `${SCHOLARSHIP_URL}${id}`,
    method: 'DELETE',
    successMessage: 'Scholarship deleted successfully',
  });
}

export interface JobOpportunityData {
  id: string;
  title: string;
  description: string;
  openDate: string;
  closeDate: string;
  link: string;
  visaCategory?: string;
  academicClassification: string;
  employmentType: string;
  company?: string;
  location?: string;
  createdAt: string;
  updatedAt: string;
}
export async function getAllJobOpportunities({
  token,
  options,
}: Kwargs): Promise<Results<JobOpportunityData>> {
  return apiCall({
    url: `${JOB_OPPORTTUNITIES_URL}list`,
    token,
    json: {
      ...options,
      where: { employmentType: { in: ['FULL_TIME', 'PART_TIME'] } },
    },
  });
}
export async function getAllInternShips({
  token,
  options,
}: Kwargs): Promise<Results<JobOpportunityData>> {
  return apiCall({
    url: `${JOB_OPPORTTUNITIES_URL}list`,
    token,
    json: {
      ...options,
      where: { employmentType: { in: ['INTERNSHIP'] } },
    },
  });
}
export interface CreateJobOpportunityOption {
  token: string;
  title: string;
  employmentType: string;
  description: string;
  openDate: string;
  closeDate: string;
  link: string;
  location: string;
  visaCategory: string;
  company: string;
  academicClassification?: string;
}
export async function createJobOpportunity({
  token,
  ...data
}: CreateJobOpportunityOption): Promise<void> {
  return apiCallVoid({
    url: `${JOB_OPPORTTUNITIES_URL}create`,
    token,
    json: data,
    successMessage: 'Job opportunities created successfully',
  });
}
export interface EditJobOppotunityOption extends CreateJobOpportunityOption {
  id: string;
}
export async function editJobOpportunity({
  token,
  id,
  ...data
}: EditJobOppotunityOption): Promise<void> {
  return apiCallVoid({
    url: `${JOB_OPPORTTUNITIES_URL}${id}`,
    method: 'PUT',
    token,
    json: data,
    successMessage: 'Job opportunities updated successfully',
  });
}
export async function deleteJobOpportunities({
  token,
  id,
}: DeleteOptions): Promise<void> {
  return apiCallVoid({
    token,
    url: `${JOB_OPPORTTUNITIES_URL}${id}`,
    method: 'DELETE',
    successMessage: 'Job opportunities deleted successfully',
  });
}

export interface CareerCoachesData {
  id: string;
  name: string;
  description: string;
  linkedin: string;
  scheduleMeeting: string;
  expertise: string;
  image: File | string;
}

export interface ISectionDetails {
  id: string;
  profileId?: string;
  type?: string;
  title?: string;
  timePeriod?: string;
  body?: string;
  image?: string;
}

export interface IStudentProfileDetails {
  id?: string;
  userId?: string;
  name?: string;
  subTitle?: string;
  summary?: string;
  work?: string;
  education?: string;
  email?: string;
  phone?: string;
  profilePicture?: string;
  headerColor?: string;
  sectionColor?: string;
  dividerColor?: string;
  section?: ISectionDetails[];
}

export async function getStudentPublicProfile({
  id,
}: {
  id: string;
}): Promise<IStudentProfileDetails> {
  return apiCall({
    url: `${STUDENT_PUBLIC_PROFILE_URL}${id}`,
  });
}

export interface Feedback {
  id: string;
  topic: string;
  lecturerId: string;
  lecturer: {
    firstName: string;
    lastName: string;
    scheduleMeeting?: string;
  };
  studentId: string;
  student: {
    firstName: string;
    lastName: string;
  };
  description: string;
  meetingDate: string;
  meetingTime: string;
}

export interface FeedbackBody {
  topic: string;
  studentId: string;
  description: string;
  meetingDate: string;
  meetingTime: string;
}

export async function createFeedback({
  token,
  ...data
}: FeedbackBody & { token: string }): Promise<Feedback> {
  const res: Feedback = await apiCall({
    url: FEEDBACK_CREATE_URL,
    method: 'POST',
    json: data,
    token,
    successMessage: 'Feedback added!',
  });
  return res;
}

export async function listFeedback({
  options,
  token,
}: Kwargs): Promise<Results<Feedback>> {
  return apiCall({
    url: FEEDBACK_LIST_URL,
    method: 'POST',
    json: options,
    token,
  });
}

export async function deleteFeedback({
  id,
  token,
}: {
  id: string;
  token: string;
}): Promise<void> {
  return apiCallVoid({
    url: `${FEEDBACK_DELETE_URL}${id}`,
    method: 'DELETE',
    token,
  });
}
