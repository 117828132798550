/* eslint-disable @typescript-eslint/no-floating-promises */
import { Btn } from '@app/components/Btn';
import { GenericModal } from '@app/components/GenericModal';
import { Email } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { useMutation } from 'react-query';

interface Props {
  open: boolean;
  onClose: () => void;
  fn: () => Promise<void>;
}

export function ConfirmResendInvite({ open, onClose, fn }: Props) {
  const mutation = useMutation(fn, {
    onSuccess: () => {
      onClose();
    },
  });

  const onDelete = () => {
    mutation.mutate();
  };

  return (
    <GenericModal
      title={
        <Stack direction="row" alignItems="center" gap={1}>
          <Email /> Confirm Resend Invite
        </Stack>
      }
      isOpen={open}
      onClose={onClose}
      useOnlyCloseButton
    >
      <Typography
        variant="body2"
        textAlign="center"
        fontWeight="bold"
        color="error"
        mb={2}
      >
        IMPORTANT: This will generate a new password for the user. If this user
        already joined, this will reset their existing password.
      </Typography>

      <Typography textAlign="center" fontWeight="bold" mb={4}>
        Are you sure want to resend invite?
      </Typography>

      <Stack
        direction="row"
        justifyContent="space-around"
        width={1}
        spacing={2}
      >
        <Btn
          variant="contained"
          onClick={onDelete}
          isLoading={mutation.isLoading}
          color="info"
        >
          Yes, Resend
        </Btn>
        <Button variant="contained" color="warning" onClick={onClose}>
          No, cancel
        </Button>
      </Stack>
    </GenericModal>
  );
}
