import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { HomePageComponents } from './HomePageComponents';

export function Settings() {
  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Dashboard',
            link: '/',
          },
          {
            label: 'Settings',
            link: '/settings',
          },
        ]}
      />
      <HomePageComponents />
    </Layout>
  );
}
