import { GenericModal, useModal } from '@app/components/GenericModal';
import { useAuth } from '@app/hooks/useAuth';
import { Add } from '@mui/icons-material';
import { Button, Stack, Box } from '@mui/material';
import { FolderItemForm } from './FolderItemForm';

interface Props {
  folderId: string;
  queryKey: string;
}
export function FolderItemsListActions({ folderId, queryKey }: Props) {
  const { tokenData } = useAuth();
  const token = tokenData?.token || '';

  const {
    isOpen: isAddFormOpen,
    openModal: openAddForm,
    closeModal: closeAddForm,
  } = useModal();

  return (
    <Box>
      <Stack direction="row" justifyContent="end" gap={2} mb={4}>
        <Button variant="contained" startIcon={<Add />} onClick={openAddForm}>
          New
        </Button>
      </Stack>

      <GenericModal
        title={
          <Stack direction="row" alignItems="center" gap={1}>
            <Add /> Add Resource
          </Stack>
        }
        isOpen={isAddFormOpen}
        onClose={closeAddForm}
        useOnlyCloseButton
      >
        <FolderItemForm
          queryKey={queryKey}
          folderId={folderId}
          token={token}
          onClose={closeAddForm}
        />
      </GenericModal>
    </Box>
  );
}
