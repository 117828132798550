import moment from 'moment';
import { toast } from 'react-toastify';

export function formatInputDate(date: string): string {
  return moment(date).format('YYYY-MM-DD');
}

export function formatInputDateTime(date: string): string {
  return moment(date).format('YYYY-MM-DDThh:mm');
}

export function formatInputTime(date: string): string {
  const temp = moment(date);
  return `${temp.hours().toString().padStart(2, '0')}:${temp
    .minutes()
    .toString()
    .padStart(2, '0')}`;
}

export function formaOutputTime(date: string, time: string): string {
  return moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').toISOString();
}

export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  return moment(date).format('MMMM Do YYYY');
}

export function formatTime(date: string): string {
  const temp = moment(date);
  return moment()
    .set({
      hours: temp.hours(),
      minutes: temp.minutes(),
    })
    .format('h:mm a');
}

export function formatDateTime(dateTimeString: string): string {
  const date = new Date(dateTimeString);
  return moment(date).format('MMMM Do YYYY, h:mm a');
}

// Add time in minutes to a current time
export function addTime(current: string, add: number) {
  const curr = moment.duration(current).asMinutes() + add;
  const temp = moment.duration(curr, 'minutes');
  return `${temp.hours().toString().padStart(2, '0')}:${temp
    .minutes()
    .toString()
    .padStart(2, '0')}`;
}

interface ApiCallOptions<D> {
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  url: string;
  token?: string;
  json?: D;
  formData?: FormData;
  successMessage?: string;
}
export async function apiCall<T, D>({
  method = 'POST',
  url,
  token,
  json,
  formData,
  successMessage,
}: ApiCallOptions<D>): Promise<T> {
  const headers = new Headers();
  if (token) headers.append('Authorization', `Bearer ${token}`);
  if (json) headers.append('Content-Type', 'application/json');

  // eslint-disable-next-line unicorn/no-null
  let body: BodyInit | null = null;
  if (json) body = JSON.stringify(json);
  if (formData) body = formData;

  const res = await fetch(url, { method, headers, body });
  if (res.status === 200) {
    const data = await res.json();
    if (successMessage) toast.success(successMessage);
    return data;
  }
  if (res.status === 400) {
    const err = await res.json();
    throw new Error(err as string);
  }
  throw new Error('Someting went wrong');
}

export async function apiCallVoid<D>({
  method = 'POST',
  url,
  token,
  json,
  formData,
  successMessage,
}: ApiCallOptions<D>): Promise<void> {
  const headers = new Headers();
  if (token) headers.append('Authorization', `Bearer ${token}`);
  if (json) headers.append('Content-Type', 'application/json');

  // eslint-disable-next-line unicorn/no-null
  let body: BodyInit | null = null;
  if (json) body = JSON.stringify(json);
  if (formData) body = formData;

  const res = await fetch(url, { method, headers, body });
  if (res.status === 200) {
    if (successMessage) toast.success(successMessage);
    return;
  }
  if (res.status === 400) {
    const err = await res.json();
    throw new Error(err as string);
  }
  throw new Error('Someting went wrong');
}

export function getFormData<
  T extends Record<keyof T, string | number | Blob | undefined>,
>(form: T, allowEmpty = false): FormData {
  const formData = new FormData();
  Object.keys(form).forEach(key => {
    const value = form[key as keyof T];
    if (
      (value !== undefined && value !== null) ||
      (value === '' && allowEmpty)
    ) {
      formData.append(key, typeof value === 'number' ? String(value) : value);
    }
  });
  return formData;
}

export function generateYoutubePlaylistEmbedUrl(url: string) {
  const splited = url.split('list=');
  return splited.length > 1
    ? `https://www.youtube.com/embed/videoseries?list=${splited[1]}`
    : undefined;
}

export function generateYoutubeVideoEmbedUrl(url: string) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11
    ? `https://www.youtube.com/embed/${match[2]}`
    : undefined;
}

export function getListFromString(
  str: string | undefined,
  separator = ', ',
): string[] {
  return str ? str.split(separator).filter(x => x) : [];
}

export function getStringFromList(list: string[], separator = ', '): string {
  return list.filter(x => x).join(separator);
}
