import { useIsMobile } from '@app/hooks/useIsMobile';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';

const colors = ['#C1272D', '#FFB100'];

interface Props {
  index: number;
  title: string;
  deadlineDate: string;
  openDate: string;
  visaCategory?: string;
  academicClassifications?: string;
  description: string;
  link: string;
  children?: React.ReactNode;
  company?: string;
  employeeType?: string;
}

export function OpportunityCard({
  index,
  title,
  deadlineDate,
  openDate,
  visaCategory,
  academicClassifications,
  description,
  link,
  children,
  company,
  employeeType,
}: Props) {
  const isMobile = useIsMobile();
  return (
    <Card elevation={5}>
      <CardContent
        sx={{
          paddingX: 4,
          pb: '16px !important',
          bgcolor: colors[index % 2],
          color: '#FFFFFF',
        }}
      >
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          alignItems={{ xs: 'left', lg: 'center' }}
          justifyContent="space-between"
        >
          <Typography
            variant={isMobile ? 'subtitle1' : 'h6'}
            fontWeight="bold"
            textTransform="uppercase"
          >
            {title}
          </Typography>
          <Typography fontWeight="bold">{company}</Typography>
        </Stack>
      </CardContent>
      <Grid
        container
        spacing={5}
        padding={4}
        direction={{ xs: 'column-reverse', lg: 'row' }}
      >
        <Grid item xs={12} lg={8}>
          <Stack
            direction="column"
            height="100%"
            justifyContent="space-between"
          >
            <Typography component="pre" sx={{ whiteSpace: 'break-spaces' }}>
              {description}
            </Typography>
            <CardActions sx={{ padding: 0, pt: 2 }}>
              <Link
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ textDecoration: 'none' }}
              >
                <Button
                  variant="contained"
                  size="small"
                  disableElevation
                  sx={{
                    textTransform: 'none',
                    py: 0,
                    px: 1,
                    bgcolor: '#ae1313',
                  }}
                >
                  Read more
                  <ArrowForwardIosOutlinedIcon
                    sx={{ fontSize: '.9rem', ml: 1 }}
                  />
                </Button>
              </Link>
            </CardActions>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Stack direction="column" spacing={1}>
            <Box sx={{ borderRadius: 3, bgcolor: '#f2e5e5' }} px={3} py={1}>
              <Typography variant="body2">{`Open date: ${new Date(
                openDate,
              ).toLocaleString()}`}</Typography>
            </Box>
            <Box sx={{ borderRadius: 3, bgcolor: '#f2e5e5' }} px={3} py={1}>
              <Typography variant="body2">{`Close date: ${new Date(
                deadlineDate,
              ).toLocaleString()}`}</Typography>
            </Box>
            <Box sx={{ borderRadius: 3, bgcolor: '#f2e5e5' }} px={3} py={1}>
              <Typography variant="body2">
                Visa Category:&nbsp;&nbsp;{visaCategory}
              </Typography>
            </Box>
            <Box sx={{ borderRadius: 3, bgcolor: '#f2e5e5' }} px={3} py={1}>
              <Stack direction="column">
                <Typography variant="body2">
                  Academic Classifications:
                </Typography>
                <Typography variant="body2">
                  {academicClassifications}
                </Typography>
              </Stack>
            </Box>{' '}
            <Box sx={{ borderRadius: 3, bgcolor: '#f2e5e5' }} px={3} py={1}>
              <Stack direction="column">
                <Typography variant="body2">
                  Employee Type:&nbsp;&nbsp;
                  {employeeType?.replace(/_/g, ' ')}
                </Typography>
              </Stack>
            </Box>
            {children}
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}
