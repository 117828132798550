/* eslint-disable @typescript-eslint/no-floating-promises */
import { Btn } from '@app/components/Btn';
import { FileUploadField } from '@app/components/FileUploadField';
import {
  ResourceFolderData,
  createResourceFolder,
  updateResourceFolder,
  IResourceType,
} from '@app/services/otherContentService';
import { Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

interface Props {
  token: string;
  onClose: () => void;
  queryKey: string;
  folderType?: IResourceType;
  id?: string;
  defaultValues?: ResourceFolderData;
}
export function FolderForm({
  token,
  onClose,
  id,
  defaultValues,
  queryKey,
  folderType,
}: Props) {
  const [values, setValues] = useState({
    title: defaultValues?.title || '',
  });
  const handleChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues(prev => ({ ...prev, [field]: event.target.value }));
    };

  const [image, setImage] = useState<File>();
  const fileOnChange = (files: File[]) => {
    if (files.length > 0) {
      setImage(files[0]);
    }
  };

  const queryClient = useQueryClient();
  const addMutation = useMutation(createResourceFolder, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      onClose();
    },
  });

  const editMutation = useMutation(updateResourceFolder, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      onClose();
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      ...values,
      image,
      token,
    };
    if (id) {
      editMutation.mutate({ ...data, id });
    } else if (folderType) {
      addMutation.mutate({ ...data, type: folderType });
    }
  };

  const error = id ? editMutation.error : addMutation.error;
  const isLoading = editMutation.isLoading || addMutation.isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4} pt={2}>
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Folder Name"
          value={values.title}
          onChange={handleChange('title')}
        />
        <FileUploadField
          label="Thumbnail (Optional)"
          onChange={fileOnChange}
          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        />

        {error && (
          <Typography color="error">{(error as Error).message}</Typography>
        )}
        <Stack direction="row" justifyContent="end" width={1} spacing={2}>
          <Btn
            variant="contained"
            color="info"
            type="submit"
            isLoading={isLoading}
          >
            Save
          </Btn>
        </Stack>
      </Stack>
    </form>
  );
}
