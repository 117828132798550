import { ConfirmDeleteModal } from '@app/components/ConfirmDeleteModal';
import { GenericModal, useModal } from '@app/components/GenericModal';
import { useAuth } from '@app/hooks/useAuth';
import {
  ResourceFolderItemData,
  deleteResource,
} from '@app/services/otherContentService';
import { Edit } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { FolderItemForm } from './FolderItemForm';

interface Props {
  data: ResourceFolderItemData;
  queryKey: string;
}

export function FolderItemsListItemActions({ data, queryKey }: Props) {
  const {
    isOpen: isEditModalOpen,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModal();

  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  const { tokenData } = useAuth();
  const token = tokenData?.token || '';

  return (
    <>
      <Stack direction="row" justifyContent="end" gap={2} py={1} px={2}>
        <Button
          disableElevation
          variant="contained"
          size="small"
          color="warning"
          onClick={openEditModal}
        >
          Edit
        </Button>

        <Button
          disableElevation
          variant="contained"
          size="small"
          color="error"
          onClick={openDeleteModal}
        >
          Delete
        </Button>
      </Stack>

      <GenericModal
        title={
          <Stack direction="row" alignItems="center" gap={1}>
            <Edit /> Edit Resource
          </Stack>
        }
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        useOnlyCloseButton
      >
        <FolderItemForm
          queryKey={queryKey}
          onClose={closeEditModal}
          token={token}
          folderId={data.folderId || ''}
          id={data.id}
          defaultValues={data}
        />
      </GenericModal>

      <ConfirmDeleteModal
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        text={data.title}
        deleteFn={() => deleteResource({ token, id: data.id })}
        queryKey="financial-literacy"
      />
    </>
  );
}
