import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import logoImg from '@assets/logo-color.png';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { Autocomplete, Avatar, Stack } from '@mui/material';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import { useProfile } from '@app/hooks/useProfile';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getAllStudents } from '@app/services/studentsService';
import { useLocation, useNavigate } from 'react-router-dom';
import { AvatarMenu } from './AvatarMenu';

interface Props {
  handleDrawerToggle: () => void;
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 50,
  padding: theme.spacing(0.5, 2, 0.5, 0),
  backgroundColor: alpha('#f1f1f1', 1),
  '&:hover': {
    backgroundColor: alpha('#f1f1f1', 1),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#707070',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '25ch',
      '&:focus': {
        width: '30ch',
      },
    },
  },
}));

// const handleNotificationButton = () => {};

export function NewAppBar(props: Props) {
  const navigate = useNavigate();

  const { handleDrawerToggle } = props;
  const { data, token } = useProfile();
  const { pathname } = useLocation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // eslint-disable-next-line unicorn/no-useless-undefined
    setAnchorEl(undefined);
  };

  const [query, setQuery] = useState('');

  const { data: searchData } = useQuery(
    [query],
    () =>
      getAllStudents(token, {
        page: 1,
        itemsPerPage: 8,
        where: query
          ? {
              firstName: {
                contains: query,
                mode: 'insensitive',
              },
            }
          : {},
      }),
    { refetchOnWindowFocus: false },
  );

  return (
    <AppBar
      position="fixed"
      elevation={10}
      sx={theme => ({
        zIndex: theme.zIndex.drawer + 1,
        bgcolor: '#ffffff',
      })}
    >
      <Toolbar sx={{ height: 80 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          minWidth="100%"
          alignItems="center"
        >
          <Box
            component="img"
            sx={{ height: 40, objectFit: 'contain' }}
            src={logoImg}
            alt="Student Portal"
          />

          {!pathname.includes('/points/history') && (
            <Search sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Autocomplete
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={searchData?.items ?? []}
                getOptionLabel={item =>
                  `${item.firstName} ${item.lastName} (${item.customId})`
                }
                onInputChange={(e, val) => val && setQuery(val)}
                renderInput={({ InputLabelProps, InputProps, ...p }) => (
                  <StyledInputBase
                    {...p}
                    {...InputProps}
                    className={undefined}
                    placeholder="Search…"
                    endAdornment={<SearchIcon sx={{ color: '#707070' }} />}
                    fullWidth
                  />
                )}
                onChange={(_, val) =>
                  val && navigate(`/points/history/${val.customId}`)
                }
              />
            </Search>
          )}
          <Stack direction="row" spacing={2} alignItems="center">
            {/* <IconButton onClick={handleNotificationButton}>
              <NotificationsIcon />
            </IconButton> */}
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar src={data?.profilePicture} alt={data?.firstName} />
            </IconButton>
            <AvatarMenu
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
            />
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ ml: 'auto', display: { sm: 'none' }, color: '#2d3145' }}
            >
              <MenuIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
