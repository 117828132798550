import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { useAuth } from '@app/hooks/useAuth';
import { Stack } from '@mui/material';
import { Actions } from './Actions';
import { Table } from './Tables';

export function Students() {
  const { isAdmin } = useAuth();

  return (
    <Layout>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BreadCrumbs
          links={[
            {
              label: 'Dashboard',
              link: '/',
            },
            {
              label: 'Students',
              link: '/users/students',
            },
          ]}
        />
        {isAdmin && <Actions />}
      </Stack>
      <Table />
    </Layout>
  );
}
