import { ConfirmDeleteModal } from '@app/components/ConfirmDeleteModal';
import { useModal } from '@app/components/GenericModal';
import { useAuth } from '@app/hooks/useAuth';
import {
  Feedback,
  deleteFeedback,
} from '@app/services/studentOpportunitiesService';
import { Button, Stack } from '@mui/material';

interface Props {
  data: Feedback;
}

export function FeedbackActions({ data }: Props) {
  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  const { tokenData } = useAuth();
  const token = tokenData?.token || '';

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" spacing={1} pt={2}>
        <Button
          disableElevation
          variant="contained"
          size="small"
          color="error"
          onClick={openDeleteModal}
        >
          Delete
        </Button>
      </Stack>

      <ConfirmDeleteModal
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        text={data.topic}
        deleteFn={() => deleteFeedback({ token, id: data.id })}
        queryKey="feedbacks"
      />
    </>
  );
}
