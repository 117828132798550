/* eslint-disable @typescript-eslint/no-floating-promises */
import { Btn } from '@app/components/Btn';
import { FileUploadField } from '@app/components/FileUploadField';
import { createEvent, editEvent, EventData } from '@app/services/eventService';
import {
  addTime,
  formaOutputTime,
  formatInputDate,
  formatInputTime,
} from '@app/util/helpers';

import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Checkbox from '@mui/material/Checkbox';
import {
  Stack,
  TextField,
  FormGroup,
  FormControlLabel,
  Typography,
  Button,
} from '@mui/material';

interface Props {
  token: string;
  onClose: () => void;
  id?: string;
  defaultValues?: EventData;
}
export function Form({ token, onClose, id, defaultValues }: Props) {
  const [values, setValues] = useState({
    name: defaultValues?.name || '',
    isNetworkingEvent: defaultValues?.isNetworkingEvent,
    description: defaultValues?.description || '',
    date: defaultValues?.startDate
      ? formatInputDate(defaultValues.startDate)
      : '',
    startDate: defaultValues?.startDate
      ? formatInputTime(defaultValues.startDate)
      : '',
    endDate: defaultValues?.endDate
      ? formatInputTime(defaultValues.endDate)
      : '',
    venue: defaultValues?.venue || '',
    link: defaultValues?.link || '',
  });
  const handleChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value =
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value;
      setValues(prev => ({ ...prev, [field]: value }));
    };

  const [image, setImage] = useState<File>();
  const fileOnChange = (files: File[]) => {
    if (files.length > 0) {
      setImage(files[0]);
    }
  };

  const queryClient = useQueryClient();
  const addMutation = useMutation(createEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries('events');
      onClose();
    },
  });

  const editMutation = useMutation(editEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries('events');
      onClose();
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      name: values.name,
      description: values.description,
      startDate: formaOutputTime(values.date, values.startDate),
      endDate: formaOutputTime(values.date, values.endDate),
      venue: values.venue,
      link: values.link,
      isNetworkingEvent: values.isNetworkingEvent,
      image,
    };
    if (id) {
      editMutation.mutate({
        token,
        id,
        ...data,
      });
    } else {
      addMutation.mutate({ token, ...data });
    }
  };

  const error = id ? editMutation.error : addMutation.error;
  const isLoading = editMutation.isLoading || addMutation.isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4} pt={2}>
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Title"
          value={values.name}
          onChange={handleChange('name')}
        />
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Description"
          value={values.description}
          onChange={handleChange('description')}
          multiline
          rows={3}
        />

        <FormGroup>
          <FormControlLabel
            checked={values.isNetworkingEvent}
            control={<Checkbox onChange={handleChange('isNetworkingEvent')} />}
            label=" Is Networking event"
          />
        </FormGroup>

        <TextField
          fullWidth
          required
          type="date"
          size="small"
          label="Date"
          value={values.date}
          onChange={handleChange('date')}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          required
          type="time"
          size="small"
          label="Start Time"
          value={values.startDate}
          onChange={handleChange('startDate')}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          required
          type="time"
          size="small"
          label="End Time"
          value={values.endDate}
          onChange={handleChange('endDate')}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: { min: addTime(values.startDate, 1) },
          }}
        />
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Venue"
          value={values.venue}
          onChange={handleChange('venue')}
        />
        <TextField
          fullWidth
          type="text"
          size="small"
          label="Link (Optional)"
          value={values.link}
          onChange={handleChange('link')}
        />
        <FileUploadField
          label="Image"
          onChange={fileOnChange}
          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
          required={!id}
        />
        {error && (
          <Typography color="error">{(error as Error).message}</Typography>
        )}
        <Stack direction="row" justifyContent="end" width={1} spacing={2}>
          <Button variant="outlined" color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Btn
            variant="contained"
            color="primary"
            type="submit"
            isLoading={isLoading}
          >
            {id ? 'Update' : 'Create'}
          </Btn>
        </Stack>
      </Stack>
    </form>
  );
}
